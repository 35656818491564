#contactos-page .ageLog.big-body #container2 #agencyContent #datTabCon tbody tr td:last-child,
#contacts-page .ageLog.big-body #container2 #agencyContent #datTabCon tbody tr td:last-child{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;


    .button__icon{

        &::after{
            position: absolute;
            background-color: var(--neutral-color-5);
            width: auto;
            height: auto;
            bottom: calc(100% - 8px);
            right: calc(100% - 25px);
            white-space: nowrap;
            content: attr(data-info);
            z-index: 100;
            padding: 8px;
            border-radius: 8px;
            font-size: var(--text-size-xxs);
            opacity: 0;
            transition: all .3s;
            pointer-events: none;
        }

        &:hover::after{
            bottom: calc(100% + 2px);
            opacity: 1;
        }


        &::before{
            position: absolute;
            bottom: calc(100% - 8px);
            right: calc(100% - 18px);
            content: "";
            width: 12px;
            height: 12px;
            background-color: var(--neutral-color-5);
            z-index: 99;
            transform: rotate(45deg);
            opacity: 0;
            transition: all .3s;
            pointer-events: none;
        }

        &:hover::before{
            bottom: calc(100% - 1px);
            opacity: 1;
        }
    }
}

.animation__mail--agency-sent{
    animation: spinAnimation 2s linear 1;
    color: var(--alert--semantic-success);
}


@keyframes spinAnimation {
    0% {
        transform: rotate(0deg);
        color: var(--neutral-color-12);
    }
    25% {
        transform: rotate(360deg) scale(0.8);
        color: var(--neutral-color-12);
    }
    50% {
        transform: rotate(720deg);
        color: var(--neutral-color-12);
    }
    75% {
        transform: rotate(1080deg) scale(0.9);
        color: var(--alert--semantic-success);
    }
    100% {
        transform: rotate(1440deg);
        color: var(--alert--semantic-success);
    }
}