/*Filtros*/
.sidbar .module {
    margin-bottom: 15px;
  }
  .slider {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .slider.slider-horizontal {
    width: 100%;
    height: 20px;
    z-index: 1000;
  }
  .slider.slider-horizontal .slider-track {
    height: 10px;
    width: 100%;
    margin-top: -5px;
    top: 50%;
    left: 0;
  }
  .slider.slider-horizontal .slider-selection, .slider.slider-horizontal .slider-track-low, .slider.slider-horizontal .slider-track-high {
    height: 100%;
    top: 0;
    bottom: 0;
  }
  .slider.slider-horizontal .slider-tick,
  .slider.slider-horizontal .slider-handle {
    margin-left: -10px;
  }
  .slider.slider-horizontal .slider-tick.triangle,
  .slider.slider-horizontal .slider-handle.triangle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-width: 0 10px 10px 10px;
    width: 0;
    height: 0;
    border-bottom-color: #036fa5;
    margin-top: 0;
  }
  .slider.slider-horizontal .slider-tick-container {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .slider.slider-horizontal .slider-tick-label-container {
    white-space: nowrap;
    margin-top: 20px;
  }
  .slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    display: inline-block;
    text-align: center;
  }
  .slider.slider-horizontal.slider-rtl .slider-track {
    left: initial;
    right: 0;
  }
  .slider.slider-horizontal.slider-rtl .slider-tick,
  .slider.slider-horizontal.slider-rtl .slider-handle {
    margin-left: initial;
    margin-right: -10px;
  }
  .slider.slider-horizontal.slider-rtl .slider-tick-container {
    left: initial;
    right: 0;
  }
  .slider.slider-vertical {
    height: 210px;
    width: 20px;
  }
  .slider.slider-vertical .slider-track {
    width: 10px;
    height: 100%;
    left: 25%;
    top: 0;
  }
  .slider.slider-vertical .slider-selection {
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .slider.slider-vertical .slider-track-low, .slider.slider-vertical .slider-track-high {
    width: 100%;
    left: 0;
    right: 0;
  }
  .slider.slider-vertical .slider-tick,
  .slider.slider-vertical .slider-handle {
    margin-top: -10px;
  }
  .slider.slider-vertical .slider-tick.triangle,
  .slider.slider-vertical .slider-handle.triangle {
    border-width: 10px 0 10px 10px;
    width: 1px;
    height: 1px;
    border-left-color: #036fa5;
    margin-left: 0;
  }
  .slider.slider-vertical .slider-tick-label-container {
    white-space: nowrap;
  }
  .slider.slider-vertical .slider-tick-label-container .slider-tick-label {
    padding-left: 4px;
  }
  .slider.slider-vertical.slider-rtl .slider-track {
    left: initial;
    right: 25%;
  }
  .slider.slider-vertical.slider-rtl .slider-selection {
    left: initial;
    right: 0;
  }
  .slider.slider-vertical.slider-rtl .slider-tick.triangle,
  .slider.slider-vertical.slider-rtl .slider-handle.triangle {
    border-width: 10px 10px 10px 0;
  }
  .slider.slider-vertical.slider-rtl .slider-tick-label-container .slider-tick-label {
    padding-left: initial;
    padding-right: 4px;
  }
  .slider.slider-disabled .slider-handle {
    background-color: #cfcfcf;
    background-image: -moz-linear-gradient(top, #DFDFDF, #BEBEBE);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#DFDFDF), to(#BEBEBE));
    background-image: -webkit-linear-gradient(top, #DFDFDF, #BEBEBE);
    background-image: -o-linear-gradient(top, #DFDFDF, #BEBEBE);
    background-image: linear-gradient(to bottom, #DFDFDF, #BEBEBE);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#DFDFDF', endColorstr='#BEBEBE',GradientType=0);
  }
  .slider.slider-disabled .slider-track {
    background-color: #e7e7e7;
    background-image: -moz-linear-gradient(top, #E5E5E5, #E9E9E9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#E5E5E5), to(#E9E9E9));
    background-image: -webkit-linear-gradient(top, #E5E5E5, #E9E9E9);
    background-image: -o-linear-gradient(top, #E5E5E5, #E9E9E9);
    background-image: linear-gradient(to bottom, #E5E5E5, #E9E9E9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E5E5E5', endColorstr='#E9E9E9',GradientType=0);
    cursor: not-allowed;
  }
  .slider input {
    display: none;
  }
  .slider .tooltip-inner {
    white-space: nowrap;
    max-width: none;
  }
  .slider .bs-tooltip-top .tooltip-inner,
  .slider .bs-tooltip-bottom .tooltip-inner {
    position: relative;
    left: -50%;
  }
  .slider.bs-tooltip-left .tooltip-inner, .slider.bs-tooltip-right .tooltip-inner {
    position: relative;
    top: -100%;
  }
  .slider .tooltip {
    pointer-events: none;
  }
  .slider .tooltip.bs-tooltip-top .arrow, .slider .tooltip.bs-tooltip-bottom .arrow {
    left: -.4rem;
  }
  .slider .tooltip.bs-tooltip-top {
    margin-top: -44px;
  }
  .slider .tooltip.bs-tooltip-bottom {
    margin-top: 2px;
  }
  .slider .tooltip.bs-tooltip-left, .slider .tooltip.bs-tooltip-right {
    margin-top: -14px;
  }
  .slider .tooltip.bs-tooltip-left .arrow, .slider .tooltip.bs-tooltip-right .arrow {
    top: 8px;
  }
  .slider .hide {
    display: none;
  }
  .slider-track {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #F5F5F5, #F9F9F9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#F5F5F5), to(#F9F9F9));
    background-image: -webkit-linear-gradient(top, #F5F5F5, #F9F9F9);
    background-image: -o-linear-gradient(top, #F5F5F5, #F9F9F9);
    background-image: linear-gradient(to bottom, #F5F5F5, #F9F9F9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F5F5F5', endColorstr='#F9F9F9',GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
  }
  .slider-selection {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #F9F9F9, #F5F5F5);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#F9F9F9), to(#F5F5F5));
    background-image: -webkit-linear-gradient(top, #F9F9F9, #F5F5F5);
    background-image: -o-linear-gradient(top, #F9F9F9, #F5F5F5);
    background-image: linear-gradient(to bottom, #F9F9F9, #F5F5F5);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F9F9F9', endColorstr='#F5F5F5',GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
  }
  .slider-selection.tick-slider-selection {
    background-color: #46c1fe;
    background-image: -moz-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#52c5ff), to(#3abcfd));
    background-image: -webkit-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -o-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: linear-gradient(to bottom, #52c5ff, #3abcfd);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#52c5ff', endColorstr='#3abcfd',GradientType=0);
  }
  .slider-track-low, .slider-track-high {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    background: transparent;
  }
  .slider-handle {
    background-color: #0478b2;
    background-image: -moz-linear-gradient(top, #0480BE, #036fa5);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0480BE), to(#036fa5));
    background-image: -webkit-linear-gradient(top, #0480BE, #036fa5);
    background-image: -o-linear-gradient(top, #0480BE, #036fa5);
    background-image: linear-gradient(to bottom, #0480BE, #036fa5);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0480BE', endColorstr='#036fa5',GradientType=0);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #0480BE;
    border: 0px solid transparent;
  }
  .slider-handle:hover {
    cursor: pointer;
  }
  .slider-handle.round {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
  .slider-handle.triangle {
    background: transparent none;
  }
  .slider-handle.custom {
    background: transparent none;
  }
  .slider-handle.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
  }
  .slider-tick {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #F5F5F5, #F9F9F9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#F5F5F5), to(#F9F9F9));
    background-image: -webkit-linear-gradient(top, #F5F5F5, #F9F9F9);
    background-image: -o-linear-gradient(top, #F5F5F5, #F9F9F9);
    background-image: linear-gradient(to bottom, #F5F5F5, #F9F9F9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F5F5F5', endColorstr='#F9F9F9',GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    filter: none;
    opacity: 0.8;
    border: 0px solid transparent;
  }
  .slider-tick.round {
    border-radius: 50%;
  }
  .slider-tick.triangle {
    background: transparent none;
  }
  .slider-tick.custom {
    background: transparent none;
  }
  .slider-tick.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
  }
  .slider-tick.in-selection {
    background-color: #46c1fe;
    background-image: -moz-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#52c5ff), to(#3abcfd));
    background-image: -webkit-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -o-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: linear-gradient(to bottom, #52c5ff, #3abcfd);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#52c5ff', endColorstr='#3abcfd',GradientType=0);
    opacity: 1;
  }
  .slider-selection {
    background: #5c469c;
  }
  .module div {
    padding: 0 2px;
  }
  .filterCount {display:none;} 
  
  .position-absolute {
    position: absolute;
  }
  .position-relative {
    position: relative !important;
  }
  .dolar-input-abs {
    left: 5px;
    top: 15px;
    font-size: 14px;
  }
  .label-input-abs {
    background: #fff;
    color: #73737a;
    font-size: 14px;
    left: 10px;
    top: -5px;
  }
  
  /*MAPA*/
  .pth-card--map {
    box-shadow: 1px 1px 4px 1px #ddd;
    border: 1px #ddd solid;
    width: 100%;
    height: auto;
    position: relative;
}

.pth-card--map > button{
  position: absolute;
  right: 0px;
  z-index: 100;
  opacity: 1;
  padding: 0px;
}

.pth-card--map a{
    position: relative;
    width: 100%;
    display: flex;
}

.pth-card--map a .card-hotel-container{
    display: grid;
    grid-template-columns: 1fr 1.4fr auto;
    width: 100%;
    min-height: 200px;
    padding: 0px;
}

.pth-card--map a .card-hotel-container .card-hotel-img .img_card{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}


.pth-card--map a .card-hotel-container .card-hotel-body{
    width: 100%;
    padding: 16px 12px 12px 12px;

    & .card-title{
        margin-top: 0px;
        margin-bottom: 4px;
    }
}

.pth-card--map a .card-hotel-container .card-hotel-footer{
  width: 100%;
  padding: 16px 12px 12px 12px;
  background-color: #f5f5f7;
}

.pth-card--map a .card-hotel-container .card-hotel-footer pth-rate-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.pth-card--map a .card-hotel-container .card-hotel-footer pth-rate-container .card-taxes.small{
  margin: 8px 0px 0px 0px;
}
.pth-card--map a .card-hotel-container .card-hotel-footer pth-rate-container .card-taxes.m-0{
  margin: 0px;
}



  
  /*skeleton*/
  .skeleton__filters {
      min-height: 200px;
  }
  .skeleton__filters--modalFilters {
      background: #f1f1f1;
  }
  .skeleton__filters--modal-header-title {
      display: flex;
      width: 100%;
      height: 35px;
      background: #f7f7f7;
      margin-bottom: 10px;
  }
  .skeleton__filters--title {
      width: 80%;
      height: 15px;
      margin: 8px 10px !important;
  }
  .skeleton__filters--module {
      padding: 10px !important;
  }
  .skeleton__filters--mobile-list {
      display:flex;
      width: 80%;
      height: 12px;
      margin: 5px;
  }
  .skeleton__card--text-price {
      display: flex;
      width: 100%;
      height: 20px;
      justify-content: right;
      align-content: right;
  }
  .skeleton__card--HotPrice {
      display: flex;
      width: 100%;
      height: 20px;
      justify-content: right;
      align-content: right;
  }
  .skeleton__card--price-note {
      display: flex;
      width: 100%;
      height: 20px;
      justify-content: right;
      align-content: right;
  }
  .skeleton__card--total-price {
      display: flex;
      width: 100%;
      height: 20px;
      justify-content: right;
      align-content: right;
  }
  .skeleton__card--btn-choose-hotel {
      display: flex;
      width: 90%;
      height: 35px;
      justify-content: right;
      align-content: right;
      padding-top:10px;
  }
  .list-group-item-action:focus, .list-group-item-action:hover {
    background-color: #fafafa;
    color: #3b3a40;
    text-decoration: none;
    z-index: 1;
  }
  .list-group-item-hover {
    background-color: #f2f2f2;
    color: #333;
  }
  .center-border-bo, .center-border-bo-ho {
    border-bottom: 1px solid #f5f5f7 !important;
    padding: .45rem .5rem !important;
  }
  .center-border-bo, .center-border-bo-ho {
    border-bottom: 1px solid #f5f5f7 !important;
    padding:  .5rem !important;
    font-size: 14px;
  }  