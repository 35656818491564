@import "variables/varColores-General";
@import "variables/varGeneral";
@import "variables/varPTH-icons";
@import "partials/PTH-icons";
/* AUTOBUSES */
#langSelector select {
  margin: 0 0 0 2px;
  float: left;
  padding: 0;
  height: auto;
  border-radius: 0;
}
td.chgbus {
  font-size: 12px;
  vertical-align: middle;
  padding-right: 5px;
}
#Content #seatSelect h2 {
  margin: 10px;
}
td.chgbus p {
  line-height: 125%;
}

.promorow p,
td.right,
.num,
.amebtn,
th.right {
  text-align: right;
}

a.chgRun,
a.chgRunDis {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: -moz-linear-gradient(center top, #ffffff 7%, #eff6fe 8%, #cae1f6 100%) repeat scroll 0 0 transparent;
  border-color: #b7dfff #97c3e5 #97c3e5 #b7dfff;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  border-width: 1px;
  color: #0066cc;
  display: inline-block;
  margin: 0 3px 0 7px;
  padding: 1px 2px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 #eeeeee;
}
/*Descuentos permanentes*/
#hsidebar div .benefits {
  background: url(../images/banner-beneficios.png) no-repeat;
  width: 198px;
  height: 216px;
  border: none;
  margin-left: 3px;
  text-indent: -9999em;
}
#hsidebar .rewardsPlus h2 {
  font-size: 13px;
}
#hsidebar #contact,
.rewardsPlus {
  margin: 10px 10px 10px 0;
}
#hsidebar div {
  border: none;
}
#unknownSelector {
  width: 84px;
}
a.chgRun:hover {
  background: -moz-linear-gradient(center top, #fafafa 7%, #e7f1fd 8%, #c3ddf5 100%) repeat scroll 0 0 transparent;
}
a.chgRunDis,
a.chgRunDis:hover {
  background: none repeat scroll 0 0 #eeeeee;
  border-color: #cccccc;
  box-shadow: 0 0 2px #cccccc inset;
  color: #999999;
  cursor: default;
}
a.chgRun:active {
  border-color: #97c3e5 #b7dfff #b7dfff #97c3e5;
}

.busrow table.depart td,
.busrow table.return td {
  color: #777777;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  vertical-align: baseline;
}

.small,
.hotCat,
.smallv {
  font-size: 12px;
  font-weight: normal;
}
.hide {
  display: none;
}
.keepPack {
  background-attachment: initial;
  background-clip: initial;
  background-color: #eeeeee;
  background-image: initial;
  background-origin: initial;
  background-position: initial initial;
  background-repeat: initial initial;
  margin-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

tr.travDat td {
  border-bottom-color: #bbd7ed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 3px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 3px;
}

.pack a.sl {
  display: block;
  font-size: 12px;
  padding: 3px 3px 5px;
}

a.sl,
a.sbl,
#header1_lnkAgencies a {
  color: #6699cc;
  text-decoration: none;
}
.busDatLab {
  text-align: right;
  width: 60px;
}

div.cbus h3 {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 5px;
}

tr.flrow td.padleft {
  padding: 0 0 0 5px;
}
tr.busrow td.padleft {
  padding: 0 0 0 3px;
}
.inlb {
  display: inline-block;
}
.busesTable .itinner td {
  padding: 3px 7px 3px 7px;
}

td.right .light {
  white-space: nowrap;
}

#divBusInfo {
  margin: 10px 0px 0px 0px;
}
.bubble .close {
  position: absolute;
  top: -9px;
  right: -9px;
}
.bubble .close,
.bubble .close:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
.close a {
  background-image: url("//3.cdnpt.com/images/shared/maps-sprite.png");
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
  text-indent: -999em;
  background-position: -12px 0;
}
.close a:hover {
  background-position: -32px 0;
}
/*            
.busrow table.depart td, .busrow table.return td {
    color: #777777;
    padding: 2px 2px 1px 0;
    vertical-align: baseline;
}*/
/*.right chgbus { width:40%;}*/
/* Aqui termina lo que arregla autobuses y vuelos */
#avisoPriv {
  border: 1px #eeeeee solid;
  margin: 10px;
  padding: 10px;
  width: 710px;
  float: right;
  border-radius: 0.5em 0.5em 0.5em 0.5em;
}

td.tot .rate-notes {
  font-size: 12px;
  font-weight: normal;
  clear: both;
  margin: 3px 0 10px 5px;
}
td.tot .rate-notes .higLit {
  color: #c52e5c;
}

/* Dropdown destinos */

/*Nuevos dropdowns jquery*/
.airportFrom input,
.airportTo input,
.selectDestination input {
  display: inline;
}
.airportFrom span,
.airportTo span,
.selectDestination span,
.yui-ac-content {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid #888;
  height: 180px;
  left: 3px;
  position: absolute;
  top: 21px;
  width: 260px;
  z-index: 100;
  border-radius: 0.25em;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
}

.yui-ac-content {
  top: 0;
  left: 0;
}

#search_packages .airportFrom ul li,
#search_packages .airportTo ul li,
#search_packages .selectDestination ul li,
.yui-ac-bd li {
  cursor: pointer;
  line-height: 130%;
  list-style: none outside none;
  margin: 0;
  padding: 8px;
}

#search_packages .airportFrom li:hover,
#search_packages .airportTo li:hover,
#search_packages .selectDestination li:hover {
  color: #000;
  background: #e6e6e6 !important;
}

/*Sprite Bancos*/
.spriteBank {
  background: url(../images/logosbancos.gif?v=1) no-repeat;
}

.bank2 /* Amex */ {
  background-position: 0px 0px;
  width: 18px;
  height: 16px;
}
.bank3 /* Banamex */ {
  background-position: -18px 0px;
  width: 16px;
  height: 16px;
}
.bank4 /* Santander */ {
  background-position: -34px 0px;
  width: 17px;
  height: 16px;
}
.bank5 /* HSBC */ {
  background-position: -51px 0px;
  width: 27px;
  height: 16px;
}
.bank6 /* Banorte */ {
  background-position: -78px 0px;
  width: 16px;
  height: 16px;
}
.bank7 /* Scotiabank */ {
  background-position: -94px 0px;
  width: 17px;
  height: 16px;
}
.bank8 /* Bancomer */ {
  background-position: -111px 0px;
  width: 16px;
  height: 16px;
}
.bank9 /* Ixe */ {
  background-position: -165px 0px;
  width: 16px;
  height: 16px;
}
.bank10 /* Inbursa */ {
  background-position: -343px 0px;
  width: 16px;
  height: 16px;
}
.bank11 /* BanBajio */ {
  background-position: -145px 0px;
  width: 20px;
  height: 16px;
}
/* .bank12  PriceTravel - no habilitado */
/* .bank13  Banco Azteca - no habilitado */
.bank14 /* Afirme */ {
  background-position: -219px 0px;
  width: 22px;
  height: 16px;
}
/* .bank15  Bank of America - no habilitado */
/* .bank16 Credomatic - no habilitado */
/* .bank17 BanCoppel - no habilitado */
.bank18 /* Davivienda - Colombia */ {
  background-position: -127px 0px;
  width: 18px;
  height: 16px;
}
.bank19 /* Liverpool */ {
  background-position: -241px 0px;
  width: 17px;
  height: 16px;
}
/* .bank20  Meadows Bank - no habilitado */
.bank21 /* BanRegio */ {
  background-position: -195px 0px;
  width: 25px;
  height: 16px;
}
.bank22 /* Banco Ahorro Famsa */ {
  background-position: -181px 0px;
  width: 14px;
  height: 16px;
}
.bank23 /* Invex */ {
  background-position: -312px 0px;
  width: 31px;
  height: 16px;
}
.bank24 /* Banca Mifel */ {
  background-position: -258px 0px;
  width: 22px;
  height: 16px;
}
.bank25 /* Banjercito */ {
  background-position: -296px 0px;
  width: 16px;
  height: 16px;
}
.bank26 /* Itaucard */ {
  background-position: -280px 0px;
  width: 16px;
  height: 16px;
}
.bank42 /* BBVA Wallet */ {
  background-position: -478px 0;
  width: 16px;
  height: 16px;
}
/* BBVA Wallet logo completo .bank42 {background-position:-360px 0 ; width:111px; height:16px;} */
.bank43 /*liverpool rosa*/ {
  background-position: -496px 0;
  width: 24px;
  height: 16px;
}
.bank44 /*liverpool gris*/ {
  background-position: -522px 0;
  width: 24px;
  height: 16px;
}
.bank49 /* Nu */ {
  background-position: -548px 0px;
  width: 24px;
  height: 16px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well dt {
  font-size: 1.25em;
}
.well dt:first-child {
  margin: 0 0 0.5em 0;
}
.well dl {
  margin: 0;
}
.well .big {
  font-size: 1.5em;
}

#hsidebar h3,
#titSea a,
.contact h3 {
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

/* arregla cotizador */
.airportFrom {
  z-index: 10;
}
.autocomplete,
yui-ac-container {
  z-index: 10;
}

/*Fix para info comision en add on*/
.info-com-addon {
  padding: 5px 0px 0px 0px;
}

/*Fix tamaño de imagen para addon*/
#addon_container li.img img {
  width: 70px;
}

/*Cruceros*/
#main #legendul {
  font-size: 12px;
}

div#left .rooms {
  margin: 0;
}
div#left .btn-group {
  float: none;
}

#ap_returnLink {
  margin: 0 0 5px 2px;
}
p {
  margin: 0.5em 0 1.5em 0;
}
#main div div.floatleft,
#ActTop h1 {
  width: auto;
}
.intro {
  font-size: 12px;
}
#ActTop .floatleft {
  margin: 0;
}
#cruiseMain {
  width: 700px;
}
#cruiseSidebar {
  margin: 0 0 0 20px;
}
#cruiseMain .span3 {
  width: 160px;
  margin: 0 12px 0 0;
}
.mainpic {
  background: url("//3.cdnpt.com/images/bgpic.gif") no-repeat scroll 0 0 transparent;
  padding: 12px 11px;
  position: relative;
}
.mainpic p {
  top: -2em;
  left: 0;
}
.photo-framed {
  background: url("//3.cdnpt.com/images/sidebar-frame.png") no-repeat;
  display: block;
  margin: 0.5em 0 1.5em 0;
}
.shipAm {
  margin: 1em 0;
}
#cruiseContent .span7 {
  width: 50%;
}
#cruiseContent .span9 {
  width: 700px;
}
.userSelrow .span3 {
  width: 180px;
}
#deckplan {
  margin: 15px 0 0 43px !important;
}
#ActTop h1 {
  margin: 5px 0;
}
/* reacomodo de informacion para tours*/
#ActTop + table.intro,
#ActTop + table + table.intro {
  table-layout: fixed;
  width: 257px;
  clear: left;
}
#ActTop + table.intro th,
#ActTop + table + table.intro th {
  text-align: left;
}
#ActTop + table.intro td {
  word-wrap: break-word;
}
/**/
#main #cruiseMain h2 {
  margin: 1.25em 0 0.5em 0;
}
h3 {
  color: #000;
  font-weight: normal;
}
.dropdown-menu li {
  margin: 0;
  padding: 0;
  background: none;
}
table.roomList td {
  font-size: 12px;
  vertical-align: middle;
}
.sym {
  margin: 0.25em 0;
}
#main h2 {
  margin: 1.25em 0 0.5em 0;
}
.table .tipText {
  display: block;
  color: #777;
  margin: 0 0 0 26px;
  font-size: 12px;
}
.divErr strong.alert {
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
}
.ap_filterName {
  margin: 0 0 0 15px;
}
.menuOptions .filterArea {
  padding: 0 0 3em 0;
  margin: 1em 0 0 0;
}
.tripStart input,
.tripEnd input {
  width: 90px;
}
.tripEnd {
  margin: 8px 0 0 0;
}
li .stepText {
  font-size: 13px;
}
#divAttributesGroup .ap_slider-duration {
  margin: 0 0.7em 1em;
}
/* Estilos en clases ap_ */
.ap_calYearDisplay {
  padding: 3px 0 0 0;
}
#left .btn-group .ap_togglerDates {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ap_togglerDates img.mSprite {
  opacity: 0.4;
  margin: -4px 0 0 -8px;
}
.mainq .ap_toggler img.mSprite {
  margin: 0;
  opacity: 1;
}
#left .module ul.ap_selectedPorts {
  font-size: 12px;
  margin: 0.5em 0 0 2em;
  padding: 0;
}
.ap_more {
  display: none;
} /* quitar cuando se implemente este link */
#ActTop {
  margin: 0 0 1em;
}

/* Self clearing floats no se incluyen relgas para IE porque PTS nunca usa IE. Si llegara a usarlo, incluimos las reglas */
.group:after,
.twocol:after,
.threecol:after,
#Content:after,
#opList li:after,
.venueList:after,
div.row:after,
.modList li:after,
#Content:after,
.thumblist li:after,
.accordion-heading:after,
#innerSel:after,
.cabinHeader:after,
#left .kid-age:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

/* Cotizador lateral con barra rosa en el título */
#ctrl {
  background: #f0f0f0;
  border-radius: 0.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  float: none;
  width: inherit;
  border: 1px solid #fff;
}
#left #ctrl h2 {
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
  margin: 0;
  color: #fff;
  padding: 0.35em;
  box-shadow: 0.15em 0.15em 0.25em #b0bcca;
  background: #036;
}
#productSearchbox,
#modsea {
  width: auto;
}

#bookerComplete,
#modTab {
  background: #fff;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  *border: 1px solid #fff;
}
.innerEngine {
  padding: 0.5em 0.75em;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
#topBar {
  padding: 5px 0 0 5px;
  width: 940px;
  margin: 0 auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#topBar .fr {
  background: #deeffa;
  color: #003767;
  font-weight: bold;
  border-radius: 0 0 3px 3px;
  padding: 0.5em 0.75em 0.25em;
  margin: -5px 0 0 0;
}
#cruiseContent .alert-success {
  margin: 1em 0;
  color: #347535;
  padding: 8px 35px 8px 14px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 0.25em;
  border: 1px solid #c5e0b9;
}

#cruiseContent .alert-info {
  color: #333;
}
.field {
  line-height: 130%;
  margin: 0.25em 0 0.75em;
}
.rmsPax th {
  line-height: 120%;
  padding: 0 7px 2px 0;
  vertical-align: bottom;
  font-size: 12px;
}
.home .rmsPax th {
  padding: 0 15px 2px 0;
}
.rmsPax td {
  padding: 0 0 4px;
}
#engines .rooms {
  border-bottom: none;
}
.field .greenButton {
  display: inline-block;
  line-height: 115%;
}
a.greenButton {
  text-decoration: none;
}

/* Sidebar derecho */
.module h3 {
  background: #d3e5f8;
  color: #01365b;
  font-weight: bold;
  margin: -5px -5px 10px -5px;
  padding: 5px;
  text-shadow: 0 1px 0 #ffffff;
}
#cruiseSidebar .module {
  background: none repeat scroll 0 0 #dcebfc;
  border: 1px solid #cbd9e8;
  border-radius: 3px 3px 3px 3px;
  font-size: 12px;
  margin: 0 0 10px;
  padding: 5px 9px;
}

/*Cotizador*/
.monthCal {
  background: url("//3.cdnpt.com/images/cal2.gif") no-repeat scroll center center transparent;
  border: 0 none;
  cursor: pointer;
  height: 21px;
  margin: 0 0 0 -2px;
  min-width: 27px;
  overflow: hidden;
  padding: 0;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
  width: 27px;
}
.fcy {
  background-position: -165px -789px;
  height: 16px;
  padding: 0 5px 0 0;
  vertical-align: middle;
  width: 16px;
}

/* Lista de camarotes disponibles */
#userSel {
  background: #fefee6;
  border: 1px solid #f3e8cc;
  border-radius: 3px;
  padding: 0.25em 0.25em;
} /* Aquí aparecen los camarotes seleccionados */
.userSelrow .dropdown-menu a,
.userSelrow .dropdown-menu a:hover {
  cursor: default;
  background: #fff;
}
#stateroomsList tr:hover td {
  background: #bee8fd;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease;
  color: #00537c;
}
#stateroomsList tr:hover .cabinType,
#stateroomsList tr:hover .cabinRate {
  color: #003a67;
}
li.select a.botAzu {
  padding: 4px 20px;
}
.noCam {
  display: inline-block;
  padding: 0 0.25em;
  background: #deeffa;
  border: 1px solid #7abcf0;
  color: #003767;
  margin: 0 0.5em 0 0;
  float: left;
  text-align: center;
  min-width: 35px;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease;
}
#stateroomsList tr:hover .noCam {
  background: #003a67;
  border: 1px solid #3595e0;
  color: #fff;
  border-radius: 2px;
}
.reqPax .noCam,
#stateroomsList tr.active .noCam {
  border: 1px solid #bcd994;
  background: #d1f2a5;
  color: #3c620b;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.noCam .btn {
  margin: 0;
  text-align: center;
}
.btn span.name {
  width: auto;
  margin: 0;
}
#main {
  margin: 0 0 5em 0;
}
#selectedCabin {
  padding: 1em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fdfafb; /* Old browsers */
  background: -moz-linear-gradient(top, #fdfafb 0%, #f2eff0 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fdfafb),
    color-stop(100%, #f2eff0)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #fdfafb 0%, #f2eff0 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #fdfafb 0%, #f2eff0 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #fdfafb 0%, #f2eff0 100%); /* IE10+ */
  background: linear-gradient(top, #fdfafb 0%, #f2eff0 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdfafb', endColorstr='#f2eff0',GradientType=0 ); /* IE6-9 */
  box-shadow: 0 0 3px rgba(34, 25, 25, 0.1);
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dbdadb;
  width: 100%;
  left: 0;
}
#stateroomsList tr.active td {
  background: #e7fbcc;
}
#innerSel {
  width: 960px;
  margin: 0 auto;
}
/* #ap_userSel {float:right;} */
#selectedCabin strong {
  text-shadow: 0 1px 0 #fff;
}
#selectedCabin .btn-group {
  margin: 0 0 0 1em;
  float: right;
  clear: none;
}
#stateRooms {
  padding: 0 0 3em 0;
}
#innerSel a.disabled {
  background: #51a351;
  opacity: 0.65;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
#innerSel .cabnSel {
  margin: 0 0 0 260px;
  float: left;
}

li.select .alert-info {
  padding: 0.5em;
  border-radius: 0.25em;
  margin: 1em 0;
}
li.select a.disabled {
  background: #0066cc;
  opacity: 0.2;
  color: #fff;
  border: 1px solid #005599;
  border-radius: 2px;
  text-shadow: none;
}
.count strong {
  font-size: 18px;
}

/* Home */
.mainq .tripStart {
  background: none;
  border: none;
}
.mainq .tripEnd {
  margin: 0;
}
a.butCom {
  text-decoration: none;
  display: inline-block;
  min-width: 53px;
}
.pax-kids {
  background: #dfeefa;
  border-bottom: 1px solid #cfe2f1;
  border-top: 1px solid #bad7ef;
  margin-top: 5px;
  padding: 5px;
  clear: both;
}
.boxAge {
  float: left;
  padding: 0 2em 0 0;
  clear: both;
}
#left .boxAge {
  padding: 0;
}
.kid-age .required {
  margin: 15px 0 0 0;
  max-width: 400px;
}
.home .kid-age .info {
  margin: 15px 0 0 0;
  max-width: 400px;
}
.kid-age-notes {
  float: left;
}
#left .kid-age-months {
  margin: 0 0 0 1em;
}
#left .boxAge {
  margin: 1em 0 0 0;
}
/* Mapa para seleccionar cubiertas */
#deckplan {
  background: #f9fbfd; /* Old browsers */
  background: -moz-linear-gradient(top, #f9fbfd 0%, #e2e5ea 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #f9fbfd),
    color-stop(100%, #e2e5ea)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f9fbfd 0%, #e2e5ea 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f9fbfd 0%, #e2e5ea 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f9fbfd 0%, #e2e5ea 100%); /* IE10+ */
  background: linear-gradient(top, #f9fbfd 0%, #e2e5ea 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9fbfd', endColorstr='#e2e5ea',GradientType=0 ); /* IE6-9 */
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.results,
#left .field .ap_selectedDate {
  font-size: 12px;
}
.nav-pills > li > a {
  position: relative;
}
.cabinInfo ul.nm li {
  line-height: 150%;
  margin: 0 0 0.5em 0;
}
.cabinInfo ul.nm li.select {
  margin: 1em 0 0 0;
}
#ap_bannerTitle,
#ap_userSel {
  display: inline-block;
  padding: 3px 0 0 0;
}
#innerSel #continueBtn {
  background-position: 0-454px;
}
#innerSel a.disabled {
  opacity: 0.25;
}
#innerSel .dropdown-menu {
  top: -32px;
}
#innerSel .dropdown-menu li {
  padding: 0 5px;
}
#innerSel .greenButton {
  display: inline-block;
}
#innerSel .tag {
  font-size: 12px;
}
#quote-pax {
  padding: 8px 35px 8px 14px;
  margin-bottom: 18px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* Vista página del producto */
.alert .greenButton {
  display: inline-block;
  margin: 0 0 0 0.5em;
}
a.greenButton:focus {
  color: #fff;
}

/* Camarotes tab de descripción del producto */
#cruiseMain .accordion-inner .span3 {
  width: 150px;
  margin: 0 0 0 20px;
}
.accordion-inner div.span5 > :first-child {
  font-size: 16px;
  color: #000;
  font-weight: normal;
}
#cruiseMain .thumbnail {
  padding: 1px 4px;
}
.accordion-inner div.group {
  margin: 0 0 2em 0;
}

/* Galerías de fotos */
.galeriesLinks li {
  margin: 0;
  background: none;
}
.galeriesLinks a {
  text-decoration: none;
}

/* Listado */
.cruiseSec {
  width: 100%;
}
td.cruiseFot {
  width: 90px;
}
.cruiseFot img [alt] {
  text-transform: lowecase;
}
.cruiseFot img {
  width: 90px;
}
.sailDate span.tripStart {
  background: none;
  border: none;
}
span.brand {
  margin: 1px 0 0 0;
}
td.select {
  width: 85px;
}
td.tot {
  width: 100px;
}
.rates td.sailDate {
  width: 580px;
}
.HotPri {
  font-weight: normal;
  font-size: 18px;
}
#detPop h2 {
  margin: 0;
}
#apply {
  position: absolute;
  right: 2em;
  bottom: 0.5em;
  left: 0.5em;
  padding: 0.5em;
  background: #f5f5f5;
  border: 1px solid #eee;
  border-radius: 2px;
  box-shadow: 0 -1px 4px #ccc;
  text-align: center;
}
#headList .le b,
#spnTotalRecords {
  font-size: 14px;
}

/* Botones seleccionar cabina */
.btn-group a.btn {
  margin: 0;
  font-size: inherit;
  text-decoration: none;
}

/* Revisar reservación */
.ban h3 {
  margin: 0 0 0.25em 0;
}
.ban ul {
  margin: 0.5em 0 1.5em 0.75em;
}
.blueboxR dl {
  margin: 0.5em 0 0 0;
}
dt {
  font-weight: bold;
  font-size: 12px;
}
dd {
  margin: 0 0 0.5em 0;
}
.blueboxR .cruiseDat td {
  padding: 0.45em 0 0 0;
}
table.roomList {
  margin: 10px 2%;
  width: 96%;
}

/* Guía para nuevos cruceristas */
.progressNav {
  margin: 1em 0 2em 0;
  border: 1px solid #eee;
  border: 1px solid #e6e6e6;
  border-top: 1px solid #f6f6f6;
  border-radius: 0.25em;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  background: #fafafa; /* Old browsers */
  background: -moz-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fafafa),
    color-stop(100%, #ebebeb)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* IE10+ */
  background: linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ebebeb',GradientType=0 ); /* IE6-9 */
}
.progressNav li {
  float: left;
}
.progressNav li:last-child a {
  width: 94.8%;
}
.progressNav a {
  padding: 4%;
  width: 92%;
  float: left;
  text-decoration: none;
  color: #333;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.progressNav > :last-child {
  border-right: none;
}
.progressNav > li:first-child {
  border-left: none;
}
.progressNav .active a,
.progressNav .active a:hover,
.progressNav .active a:visited :hover {
  background: #dcdee1; /* Old browsers */
  background: -moz-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #dcdee1),
    color-stop(18%, #e6e8eb),
    color-stop(100%, #fafafa)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* IE10+ */
  background: linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdee1', endColorstr='#fafafa',GradientType=0 ); /* IE6-9 */
}
.progressNav .active a:hover .stepText {
  text-decoration: underline;
}
.progressNav a:hover {
  background: #ecf6fe; /* Old browsers */
}
.progressNav a:hover .step {
  background: #003767;
}
.progressNav .active .step,
.progressNav .active a:hover .step {
  color: #fff;
  opacity: 0.9;
  text-shadow: 0 -1px 0 #a9113f;
  background: #c20b42; /* Old browsers */
  background: -moz-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #c20b42),
    color-stop(100%, #ed1556)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* IE10+ */
  background: linear-gradient(top, #c20b42 0%, #ed1556 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c20b42', endColorstr='#ed1556',GradientType=0 ); /* IE6-9 */
}
.progressNav .active .stepText {
  color: #ed1556;
}
li .step {
  font-size: 24px;
  display: inline-block;
  background: #003767;
  color: #fff;
  padding: 0.25em 0.35em;
  text-shadow: 0 -1px 0 #5d91af;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3) inset;
  border-radius: 0.15em;
  background: #6aa8cc; /* Old browsers */
  background: -moz-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #6aa8cc),
    color-stop(50%, #7dbadc),
    color-stop(100%, #8ac6e8)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* IE10+ */
  background: linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6aa8cc', endColorstr='#8ac6e8',GradientType=0 ); /* IE6-9 */
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

li .stepText {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 0.5em;
  position: relative;
  top: -5px;
  color: #397598;
  text-shadow: 0 1px 0 #fff;
}
.threeStep li {
  width: 33%;
}

/* Imágenes de fondo para banners de cruceros */
#cruise1 {
  background: url("//3.cdnpt.com/images/cruises/bannerbg.png") repeat;
}
#cruise2 {
  background: url("//3.cdnpt.com/images/cruises/bannerbg2.png") repeat;
}
#cruise3 {
  background: url("//3.cdnpt.com/images/cruises/bannerbg3.png") repeat;
}
.tourSnippets {
  border: 3px solid #fff;
  border-radius: 0.25em;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 1em;
  background: #f8f8f8 url("//3.cdnpt.com/images/cruises/lines.png") repeat;
}
.tourSnippets .span4 {
  width: 190px;
}
.tourSnippets .nm {
  margin: 0;
}
#cruise1 .bannerTxt {
  margin: 0.5em 0 0 2.5em;
}
#cruise2 .bannerTxt {
  margin: 1em 0 0 2em;
}
#cruise2 .bannerImg {
  margin: 1em 2em 0 0;
}
#cruise3 .bannerTxt {
  margin: 2em 0 0 2em;
}
#cruise3 .bannerImg {
  margin: 1em 1em 0 0;
}

/* Media queries para tablets de 10 pulgadas o 1024 x 768 */

/* iPads (landscape) -----------  */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #main {
    padding: 10px;
    width: 680px !important;
  }
  .ap_filterName {
    margin: 0 0 0 10px;
    padding: 0.25em;
    width: 140px;
  }
  .home .kid-age .info {
    width: 300px;
    line-height: 150%;
  }
  #cruiseContent .span7 {
    width: 50%;
  }
  .cabinType {
    width: 50%;
  }
  #innerSel {
    width: 940px;
  }
  #innerSel .cabnSel {
    margin: 0;
  }
  #Content #right {
    left: 760px !important;
  }
  #cruiseMain {
    width: 72%;
  }
  #cruiseMain .accordion-inner .span5 {
    width: 55%;
  }
  #cruiseMain .span3 {
    width: 31%;
  } /* Para vista de amenidades del barco */
  #cruiseMain .span3 h3 {
    font-size: 1.15em;
  }
  .span3 img.grayIcon {
    display: none;
  }
}

/* No esta este codigo en el minificado de general, se comenta para no exportar algo distinto */
/* iPads (portrait AND landscape) ----------- */
/*@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
  .ap_filterName {width:120px;}
  #maxDuration {width:24px;}
  #left .kid-age-months {margin:0; float:left;}
  }
*/
/* iPads (portrait) ----------- */
/*@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  #main  {padding:10px 20px;}
  #cruiseMain, #cruiseContent .span7 {width:100%; float:none;}
  #cruiseSidebar {float:none; width:100%; margin:0;}
  #cruiseTabs li, #cabnSelBottom {margin:0 !important;}
  #cruiseTabs a {padding:.25em;}
  #cruiseMain .accordion-inner .span5 {  width: 65%;}
  #main {padding:10px; width:72%;}
  #cruiseMain .span3 {width:31%;}
  #cruiseMain .span3 h3 {font-size:1.15em;}
  .span3 img.grayIcon {display:none;}
  #cabinLegend {display:none;}
  #innerSel {width:940px;}
  .cabinType, .cabinRate, .ap_selectedDate {font-size:16px !important;}
  .cabnSel a, #cabnSel a {padding:2px;}
}
*/
.sidbar .module {
  margin: 0;
  padding: 0px;
  background: $white;
}

.sidbar h3 {
  background: none repeat scroll 0 0 transparent;
}
.sidbar .module h4 {
  border-top: 1px solid #eeeeee !important;
  color: #555555 !important;
  font-size: 14px !important;
}

#hsidebar div {
  border: none;
}
#hsidebar #contact {
  border: 1px solid #f2f2f2;
}

#left #wrapper .sidbar .module h4 {
  border-top: 1px solid #ffffff;
  color: #003767;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  padding: 8px 4px;
}
.module {
  div {
    padding: 12px 8px;
  }
}
.module li input {
  float: left;
  margin: 0 4px 0 15px;
  vertical-align: middle;
}
#left .module li a {
  padding: 4px;
}
#left .module a.ap_departurePort {
  display: block;
  font-size: 12px;
  margin: 0.25em 0 1em;
  padding: 0;
}

.module .box,
.ap_departurePort {
  float: none;
}
#headList .le,
#headList .ri {
  width: auto;
}
#itineraryMap #itineraryList {
  background: #fff !important;
}

/*Boton reservar cruceros*/
.greenButton,
#innerSel #continueBtn {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: #1a8a01;
  background-position: 0 -454px;
  background-repeat: repeat-x;
  border-color: #18ac00 #167700 #167700 #18ac00;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  letter-spacing: -1px;
  font-size: 16px;
  font-weight: bold;
  padding: 3px 10px;
  text-decoration: none;
}

#innerSel #continueBtn {
  background-position: 0-454px !important;
}

.dropdown-menu {
  min-width: 140px !important;
}
#cabinOverlay {
  background: #fff;
}

#cruiseContent,
#container2 #Content {
  overflow: visible !important;
  padding-right: 2px;
  padding-left: 2px;
}
/*se sobre escribe el visible important y no permite scrollbars sobre ese*/
.big-body #container2 #Content {
  overflow: auto !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
/* Guía para nuevos cruceristas */
#ltboxContent,
.progressNav {
  margin: 1em 0 2em 0;
  border: 1px solid #eee;
  border: 1px solid #e6e6e6;
  border-top: 1px solid #f6f6f6;
  border-radius: 0.25em;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  background: #fafafa; /* Old browsers */
  background: -moz-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fafafa),
    color-stop(100%, #ebebeb)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* IE10+ */
  background: linear-gradient(top, #fafafa 0%, #ebebeb 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ebebeb',GradientType=0 ); /* IE6-9 */
}
.progressNav li {
  float: left;
  display: block;
}
.progressNav li:last-child a {
  width: 94.8%;
}
.progressNav a {
  padding: 4%;
  width: 92%;
  float: left;
  text-decoration: none;
  color: #333;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/*.progressNav group threeStep {} */
.progressNav > :last-child {
  border-right: none;
}
.progressNav > li:first-child {
  border-left: none;
}
.progressNav .active a,
.progressNav .active a:hover,
.progressNav .active a:visited :hover {
  background: #dcdee1; /* Old browsers */
  background: -moz-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #dcdee1),
    color-stop(18%, #e6e8eb),
    color-stop(100%, #fafafa)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* IE10+ */
  background: linear-gradient(top, #dcdee1 0%, #e6e8eb 18%, #fafafa 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdee1', endColorstr='#fafafa',GradientType=0 ); /* IE6-9 */
}
.progressNav .active a:hover .stepText {
  text-decoration: underline;
}
.progressNav a:hover {
  background: #ecf6fe; /* Old browsers */
}
.progressNav a:hover .step {
  background: #003767;
}
.progressNav .active .step,
.progressNav .active a:hover .step {
  color: #fff;
  opacity: 0.9;
  text-shadow: 0 -1px 0 #a9113f;
  background: #c20b42; /* Old browsers */
  background: -moz-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #c20b42),
    color-stop(100%, #ed1556)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #c20b42 0%, #ed1556 100%); /* IE10+ */
  background: linear-gradient(top, #c20b42 0%, #ed1556 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c20b42', endColorstr='#ed1556',GradientType=0 ); /* IE6-9 */
}
.progressNav .active .stepText {
  color: #ed1556;
}
li .step {
  font-size: 24px;
  display: inline-block;
  background: #003767;
  color: #fff;
  padding: 0.25em 0.35em;
  text-shadow: 0 -1px 0 #5d91af;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3) inset;
  border-radius: 0.15em;
  background: #6aa8cc; /* Old browsers */
  background: -moz-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #6aa8cc),
    color-stop(50%, #7dbadc),
    color-stop(100%, #8ac6e8)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* IE10+ */
  background: linear-gradient(top, #6aa8cc 0%, #7dbadc 50%, #8ac6e8 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6aa8cc', endColorstr='#8ac6e8',GradientType=0 ); /* IE6-9 */
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

li .stepText {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 0.5em;
  position: relative;
  top: -5px;
  color: #397598;
  text-shadow: 0 1px 0 #fff;
}
.threeStep li {
  width: 33%;
}

ul li,
ul.ex li {
  background: none;
  padding: 0px;
  margin: 0px;
}

/* Comisiones ------- */
#detPop h3 {
  font-weight: normal;
  margin: 10px 0 0 5px;
  padding-top: 5px;
  border-top: 1px dotted #ccc;
}
#commissionList {
  margin: 5px;
  font-size: 12px;
}
#commissionList th .dialogWrap {
  font-weight: normal;
}
#commissionList th .dialogWrap p {
  margin-bottom: 10px;
  line-height: 1.2em;
}
#commissionList th {
  background: #f9f9f9;
  border-right: none;
  border-left: none;
  font-size: 12px;
  text-align: right;
  line-height: 1.1em;
}
#commissionList th .note {
  display: block;
  color: #666;
  font-size: 12px;
  float: none;
  font-weight: normal;
  white-space: nowrap;
}
body #commissionList th,
#commissionList td {
  padding: 5px 6px;
  border: 1px solid #ddd;
}
#commissionList td {
  background: #fff;
}
#commissionList td,
body #commissionList th {
  border-left-style: dotted;
  border-right-style: dotted;
}
#detPop #commissionList th,
#detPop #commissionList td {
  padding: 1px 6px;
}
#commissionList .total td {
  border-top: 1px solid #ddd;
}
#commissionList .last td {
  padding-top: 15px;
}
ul#comOptions {
  margin-bottom: 0;
}
#details table.comision {
  margin: 0 0 15px 10px;
}
table.comision th.commissionData {
  line-height: 1em;
  padding: 5px;
}
table.comision td.commissionData {
  vertical-align: middle;
}
table.comision td.commissionCurrency {
  font-weight: normal;
  color: #333;
}
table.comision tr.comisionHead td {
  border-bottom: 1px solid #ccc;
  padding-bottom: 3px;
}
table.comision td.msi {
  border-left: 1px solid #ccc;
  padding-left: 10px;
}
.commissionData {
  background: #ffe;
  font-weight: bold;
  color: #003859;
}
.comissionsDetail td .note {
  font-weight: normal;
  float: left;
  color: #666;
  font-size: 12px;
  margin-right: 5px;
}
#commissionList th.commissionData,
#commissionList td.commissionData {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  box-shadow: 0 0 3px -3px #999;
  /*outline: 1px solid #ddd;*/
}
#commissionList th.commissionData {
  border-top: 1px solid #ccc;
}
#commissionList .total td.commissionData {
  border-bottom: 1px solid #ccc;
}
#commissionList td.commissionData {
  background: #ffe;
}

#commissionList tr.commissionListHead td {
  border: none;
  background: none;
  padding: 3px 0;
}
#commissionList tr.commissionListHead td.paymentTypeHead {
  border: none;
  padding: 3px 13px 3px 5px;
  background: #ddd url(../images/bgcomtd.gif) right bottom no-repeat;
}

#main .agency-commission-ban {
  border: 1px solid #ddd;
  background: #fffff5;
  padding: 10px;
  position: relative;
}
.agency-commission-ban .totalCommission {
  font-size: 18px;
  margin: 5px 0 10px 0;
  color: #003859;
}
.agency-commission-ban .note a {
  font-size: 12px;
}
.agency-commission-ban table#commissionList {
  background: #fff;
  margin: 0;
  width: auto;
  border: 1px solid #eee;
  margin-top: 7px;
}
.agency-commission-ban td .note {
  margin-right: 5px;
  float: left;
  color: #666;
}

/* Comisiones en Checkout */
#divPayNow #commissionList {
  margin: 10px 0 0 0;
}
#chkOut #commissionList tr.commissionListHead td.paymentTypeHead {
  background: #ddd url(../images/bgcomtd2.gif) right bottom no-repeat;
}
#chkOut .banerComision {
  width: auto;
}
.dialogWrap {
  margin: 5px 0 0 15em;
  position: absolute;
  width: 210px;
}

.dialogContainer:after {
  border-bottom-color: #ffffff;
  border-width: 10px;
  left: 15px;
}
.dialogContainer:after,
.dialogContainer:before {
  border: medium solid transparent;
  bottom: 100%;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
}
.dialogContainer:before {
  border-bottom-color: #c0c0c0;
  border-width: 11px;
  left: 14px;
}
.dialogContainer:after,
.dialogContainer:before {
  border: medium solid transparent;
  bottom: 100%;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
}
.dialogContainer {
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #c0c0c0;
  box-shadow: 0 0 3px #cccccc;
  color: #333333;
  padding: 1em;
  position: relative;
  text-align: left;
  width: 15em;
}
/*...Estilos caja de autobuses...*/
element.style {
  display: block;
  margin-left: -400px;
  position: absolute;
  z-index: 99;
}
#detPop,
.detPop {
  background: none repeat scroll 0 0 #ffffff;
  border-color: #cccccc #999999 #999999 #cccccc;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 3px #999999;
  padding: 5px;
  width: 445px;
}
.itinner tbody > tr:nth-child(2n + 1) > td {
  background: none;
}
.itinner tbody > tr:nth-child(2n + 1) > td:first-child {
  border-bottom-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
}
.itinner tbody > tr:nth-child(2n + 1) > td:last-child {
  border-bottom-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.escala {
  background: none repeat scroll 0 0 #ffffe7;
  margin: 0 0 0 50px;
  padding: 5px 0 5px 10px;
}

div#demoUnit {
  background: none repeat scroll 0 0 #df3a01;
  color: #effbf8;
  font-size: 18px;
  font-weight: bold;
  left: 0;
  padding: 10px;
  position: fixed;
  z-index: 10000;
}
/*...Botones CSS...*/

input.btnLinks {
  color: #fff;
  padding: 5px 10px;
  letter-spacing: normal;
  font-size: 16px;
  font-weight: normal;
  border-radius: $radius-sm;
  width: auto;
  height: auto;
}

#divComplete .banres a {
  text-decoration: none;
}
#main .floatright a {
  text-decoration: none;
}
/*...Hoteles outlet2013...*/
#customerMenuToggler .butCom,
.customer-overlay .butCom {
  font-size: 14px;
  color: #fff;
}
.ovrshad {
  box-shadow: 2px 4px 8px rgba(45, 71, 97, 0.6);
}
.ovrlist {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 #ffffff;
  border-color: #bbbbbb #bbbbbb transparent;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 1px medium;
  display: none;
  float: left;
  min-width: 220px;
  z-index: 1000;
}

#topheader .customer-overlay li {
  padding: 0.5em 1em;
}
#topheader .text-right {
  text-align: right;
}
#topheader .customer-overlay ul {
  font-size: 13px;
}
#topheader ul.moreopts {
  background: none repeat scroll 0 0 #f1f5fb;
  box-shadow: 0 1px 2px #ccd9ea inset;
  text-shadow: 0 1px 0 #ffffff;
}
.text-big {
  font-size: 1.25em;
  color: #0066cc;
}
.text-big:hover {
  color: #003355;
  text-decoration: none;
}
#topheader {
  font-size: 12px;
  height: 95px;
  position: relative;
  overflow: visible;
}
/* Nuevo cliente - reglas generales */
.customer-name {
  color: inherit;
  margin-left: 20%;
  margin-right: 20%;
  font-size: 18px;
}
.customer-welcome {
  font-size: 2.5em;
  font-weight: bold;
  letter-spacing: -4px;
  line-height: 1;
  margin: 0;
  color: inherit;
}
.customer-brand {
  opacity: 0.8;
}
.customer-name .btn-large {
  font-size: 1.25em;
}
.customer-name_input {
  font-size: 2em;
  width: 6em;
  margin: 0.25em 0;
  vertical-align: middle;
}

.customer-name_training {
  margin-top: 8em;
  font-size: large;
  display: inline-block;
}
.customer-overlay li {
  padding: 0.5em 1em;
}
.alert-training-mode {
  padding: 0.75em 1em;
  background: #ffc;
  color: #111;
  font-size: 16px;
  overflow: hidden;
}
/*Ofertas online*/
div .spec .rateDown {
  color: #008000 !important;
}

#carousel {
  width: 705px;
}

.location {
  text-indent: -9999em !important;
}
/*.col {margin-left: 20px !important;}*/
table.actDates th,
table.actDates td {
  padding: 4px 7px !important;
}

.departure {
  text-indent: -9999em !important;
}
#avisoPriv {
  border: 1px #eeeeee solid !important;
  margin: 10px !important;
  padding: 10px !important;
  width: 710px !important;
  float: right !important;
  border-radius: 0.5em 0.5em 0.5em 0.5em !important;
}
div#modTab {
  margin-bottom: 20px;
}

#addon_content .spec a.greenButton {
  margin-left: 5px;
}

#tourInfo {
  margin-left: 0;
}

/*Tablas info autobus*/

#busRunsBodyList .itDat .itdata {
  width: 400px;
}

/*Quitar raya de los botones*/
.boxPri a {
  text-decoration: none;
}

/*sprite para autos*/
img.spritecar {
  background-image: url("https://b2b2c.cdnpt.com/general/images/cars-agency.png");
  background-repeat: no-repeat;
  width: 90px;
  height: 24px;
}

img.rentalcars {
  background-position: 0 -139px;
}
.typeSelector {
  margin: 0 0 15px;
  overflow: hidden;
}
.typeSelector li {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #efefef 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #ffffff),
    color-stop(100%, #efefef)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 0%, #efefef 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 0%, #efefef 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #efefef 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffffff 0%, #efefef 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#efefef',GradientType=0 ); /* IE6-9 */

  border-color: #dddddd #dddddd #dddddd #ffffff;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  float: left;
  line-height: 2.3em;
  margin: 0;
  padding: 0 5px;
}
.typeSelector li:hover {
  background: none repeat scroll 0 0 #f3f3f3;
}
.typeSelector li:first-child {
  border-left-color: #dddddd;
  border-radius: 5px 0 0 5px;
}
.typeSelector li:last-child {
  border-radius: 0 5px 5px 0;
}
.typeSelector li.selected {
  background: linear-gradient(to bottom, #eeeeee 0%, #fcfcfc 100%) repeat scroll 0 0 transparent;
  box-shadow: 0 3px 3px #dddddd inset;
}
.typeSelector li,
.typeSelector input,
.typeSelector label {
  cursor: pointer;
}
.typeSelector input {
  vertical-align: -7%;
}
img.europcar {
  background-position: 0 0;
}
img.national {
  background-position: 0 -26px;
}
img.avis,
img.avisintertours {
  background-position: 0 -52px;
}
img.thrifty,
img.thriftyavasa {
  background-position: 0 -78px;
}
img.hertz,
img.hertzusa {
  background-position: 0 -104px;
}
img.rentalcars,
img.rentalcarsintertours {
  background-position: 0 -139px;
}
img.hertzinternational {
  background-position: 0 -104px;
}
img.dollar,
img.dollaravasa {
  background-position: 0 -166px;
}
img.firefly {
  background-position: 0 -191px;
}
img.budget{
  background-position: 0 -215px;
}

ul.ui-autocomplete {
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  width: 250px;
}
ul.ui-autocomplete a {
  padding: 4px 7px;
  display: block;
  color: #888;
  text-decoration: none;
}
ul.ui-autocomplete a:hover,
ul.ui-autocomplete a.ui-state-focus,
ul.ui-autocomplete a.ui-state-active {
  background: #f3f3f3;
  color: #333;
}

table tr.extrasInfo td {
  padding-left: 1em;
}
.resExtras {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
}
.resExtras h4 {
  background: none;
}
.resExtras td {
  vertical-align: top;
  line-height: 130%;
}
.resExtras table {
  width: auto;
}
.extraServiceFlight {
  background: #eee;
  color: #555;
  font-size: 12px;
  letter-spacing: normal;
  padding: 3px 6px;
}

/* Modulo de best rates de vuelo */
.list-best-rates {
  margin: -0.3em 0 1em 0;
  background: #fff;
  border: 1px solid #eee;
  border-bottom-color: #ddd;
}
.list-best-rates .section-title {
  border-bottom: 1px solid #eee;
  padding: 0.75em 1em 0.25em;
}
.list-best-rates .section-title span {
  display: inline-block;
}
.list-best-rates .leg {
  border-bottom: 1px solid #eee;
  padding: 0.5em 1em;
}
.list-best-rates .leg:last-child {
  border-bottom: none;
}
.list-best-rates .selected-leg {
  background: #f0f7fc;
}
.list-best-rates .schedule,
.list-best-rates .schedule:hover {
  background: none;
  margin: 0;
  padding: 0;
}
.list-best-rates .airline {
  width: 25%;
  line-height: 36px;
}
.list-best-rates .airline-icon-wrap {
  float: left;
  width: 35%;
  text-align: center;
}
.list-best-rates .airline-name {
  display: block;
  float: left;
  width: 60%;
  line-height: 18px;
  margin: 0.75em 0 0 0.5em;
}
.departure-flight {
  margin: 0 2em 0 0;
}
.depart-flight-title {
  margin: 0 2em 0 0;
  width: 110px;
}
.upsell-rate {
  margin: 0 1em 0 0;
}
.button-holder .btn {
  margin-top: 0;
}

/* Reservaciones a nombre de terceros --- */
.alert-reservation-name {
  width: 100%;
  padding: 10px 0;
  font-size: 15px;
  background: #ffc;
  margin-top: 3px;
}
.alert-reservation-name a {
  text-decoration: underline;
}
#cboxLoadedContent {
  background: #fff;
}
#agencies-list,
#agents-list {
  padding: 5px 15px;
}
#agencies-list .search,
#agents-list .search {
  margin: 10px 0;
}
#agencies-list .btn,
#agents-list .btn {
  margin-top: 0;
}
#agencies-list .search input,
#agents-list .search input {
  height: 22px;
}
#cboxClose {
  border: none;
}
.agency-address,
td.agency-address {
  font-size: 12px;
  line-height: 1.2em;
}
.selected-agency {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}
.agency-name {
  font-size: 16px;
}
#agents-list .selected td {
  background: #ffd;
}

/*Lista de formas de pago*/
.form-banks th {
  background: #f2f2f2;
}
.form-banks td {
  font-size: 12px;
  vertical-align: middle;
}
.form-banks tr.title th {
  background: linear-gradient(to bottom, #f5f5f5 0%, #ebebeb 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  border-color: #dddddd;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 0 #ffffff inset;
  color: #565656;
  font-weight: bold;
  padding: 0.5em;
  text-shadow: 0 1px 0 #ffffff;
}
.form-banks .msi {
  color: #036fad;
  line-height: 165%;
  list-style: none;
  font-size: 15px;
}

.form-banks .pagosFijos {
  font-size: 15px;
  line-height: 165%;
  color: #5f5f5f;
}

.form-banks td.plans {
  color: #666666;
  padding: 0.25em;
  width: 260px;
}

.form-banks .odd td,
.odd th {
  background: none repeat scroll 0 0 #f6f6f6;
}

.form-banks .msi span,
.pagosFijos span {
  font-size: 1.25em;
  font-weight: bold;
}

.form-banks .issuer div {
  color: #666666;
  font-size: 1.25em;
}

.form-banks img {
  margin-right: 5px;
}

.form-banks td.issuer {
  padding: 0.25em 1em 0.5em 0.25em;
  width: 185px;
}

.opinions a.h-icons {
  color: #48afca;
  font-size: 18px;
}

.h-icons {
  font: 12px/1em "pt-icons";
  text-decoration: none;
  vertical-align: bottom;
}

.rateLabel {
  font-weight: bold;
}

@font-face {
  font-family: "pt-icons";
  src: url("//3.cdnpt.com/cssshared/fonts/pt-icons.eot?v=4");
  src:
    url("//3.cdnpt.com/cssshared/fonts/pt-icons?#iefix") format("embedded-opentype"),
    url("//3.cdnpt.com/cssshared/fonts/pt-icons.woff?v=4") format("woff"),
    url("//3.cdnpt.com/cssshared/fonts/pt-icons.ttf?v=4") format("truetype"),
    url("//3.cdnpt.com/cssshared/fonts/pt-icons.svg#pt-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="pt-icon-"]:before,
[class*=" pt-icon-"]:before {
  font-family: "pt-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pt-icon-twitter:before {
  content: "a";
}
.pt-icon-video:before {
  content: "b";
}
.pt-icon-chevdown-bld:before {
  content: "c";
}
.pt-icon-halfstar2:before {
  content: "d";
}
.pt-icon-logopricetravel:before {
  content: "e";
}
.pt-icon-star:before {
  content: "f";
}
.pt-icon-fivestar:before {
  content: "fffff";
}
.pt-icon-gplus:before {
  content: "g";
}
.pt-icon-list:before {
  content: "h";
}
.pt-icon-dropdown:before {
  content: "i";
}
.pt-icon-facebook-sq:before {
  content: "j";
}
.pt-icon-position:before {
  content: "k";
}
.pt-icon-search:before {
  content: "l";
}
.pt-icon-opinion:before {
  content: "m";
}
.pt-icon-opinion-half:before {
  content: "n";
}
.pt-icon-opinion-empty:before {
  content: "o";
}
.pt-icon-departure:before {
  content: "p";
}
.pt-icon-return:before {
  content: "q";
}
.pt-icon-zoomin:before {
  content: "r";
}
.pt-icon-discount:before {
  content: "s";
}
.pt-icon-video-sq:before {
  content: "t";
}
.pt-icon-picture:before {
  content: "u";
}
.pt-icon-check:before {
  content: "v";
}
.pt-icon-info-circle:before {
  content: "w";
}
.pt-icon-airplane-up:before {
  content: "x";
}
.pt-icon-bed:before {
  content: "y";
}
.pt-icon-bus:before {
  content: "z";
}
.pt-icon-calendar:before {
  content: "A";
}
.pt-icon-people:before {
  content: "B";
}
.pt-icon-chevup:before {
  content: "C";
}
.pt-icon-chevdown:before {
  content: "D";
}
.pt-icon-traslado:before {
  content: "E";
}
.pt-icon-remove:before {
  content: "F";
}
.pt-icon-city:before {
  content: "G";
}
.pt-icon-supersaver:before {
  content: "H";
}
.pt-icon-cutlery:before {
  content: "I";
}
.pt-icon-suitcase:before {
  content: "J";
}
.pt-icon-mobile:before {
  content: "K";
}
.pt-icon-camera:before {
  content: "L";
}
.pt-icon-shield:before {
  content: "M";
}
.pt-icon-plane-airport:before {
  content: "N";
}
.pt-icon-globe:before {
  content: "O";
}
.pt-icon-heart:before {
  content: "P";
}
.pt-icon-heart-empty:before {
  content: "Q";
}
.pt-icon-book:before {
  content: "R";
}
.pt-icon-coffee:before {
  content: "S";
}
.pt-icon-angle-left:before {
  content: "T";
}
.pt-icon-angle-right:before {
  content: "U";
}
.pt-icon-money:before {
  content: "V";
}
.pt-icon-user-group:before {
  content: "W";
}
.pt-icon-comment:before {
  content: "X";
}
.pt-icon-clock:before {
  content: "Y";
}
.pt-icon-phone:before {
  content: "Z";
}
.pt-icon-compass:before {
  content: "0";
}
.pt-icon-monument:before {
  content: "1";
}

a.addDestinationLink img.mSprite {
  background: url(../images/spritesmaster6.gif) no-repeat scroll -191px -429px rgba(0, 0, 0, 0) !important;
  float: left !important;
  height: 16px !important;
  width: 16px !important;
}
.warning {
  background: #f7ecd3;
  border: 1px solid #e4d5b1;
  padding: 0.5em;
  margin: 0.5em 0;
}
#cruiseMain .galTitle {
  font-size: 1em;
}
#cruiseMain .picArea img,
.main-info .picArea img {
  position: static;
}
#cruiseContent .row {
  margin-left: 0 !important;
}
#divRoomQuoted {
  z-index: 1;
}
div#divRoomQuoted {
  z-index: 10000 !important;
}
#wraPop,
.wraPop {
  background: #fff;
  width: 80px;
  text-align: center;
  position: relative;
  top: 1px;
  left: 375px;
  border-right: 1px solid #999;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
#tabPop,
.tabPop {
  background: #fff;
  height: 30px;
  font-size: 12px;
  overflow: visible;
}
.hotPagCont .inter h3 {
  padding: 5px 0 6px 76px;
}

/* Pasos en la guia de nuevos cruceristas */
.threeStep li {
  padding: 0 !important;
}

/* Autobuses */
.leg-title {
  overflow: hidden;
}

/*Landing page colombia*/
.itemRate {
  text-align: center;
  width: 20%;
  float: left;
}

/* ESTILOS PARA EL WIDGET EN EL SIDEBAR --- */

/* Módulos en el sidebar */
.sidebar-title {
  background: #ddd;
}
.sidebar-title .close,
.sidebar-title .close:hover {
  color: #fff;
  text-shadow: 1px 0 0 rgba(0, 0, 0, 0.3);
  font-size: 24px;
  font-weight: normal;
}
.sidebar-title .close {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.sidebar-title .close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.sidebar-module .module-content {
  background: #fff;
  border-top: none;
  margin-bottom: 15px;
  overflow: hidden;
}
#wrapper {
  margin: 0;
  .sidebar-module {
    border: 2px solid $gray-100;
    margin-bottom: 12px;
  }
}
#left .sidbar {
  border: 2px solid $gray-100;
  margin-bottom: 12px;
  overflow: hidden;
}
/* Módulo del widget */
.sidebar-widget #ptw-container.ptw-small,
.rmsPax th,
.sidebar-widget #ptw-container.ptw-small input {
  font-size: 12px;
}
.sidebar-widget #ptw-container.ptw-small input.ptw-submit-btn {
  font-size: 13px;
}

/* Módulo del resumen */
.sidebar-summary .module-content {
  padding: 10px;
  font-size: 12px;
}
.sidebar-summary p {
  margin-bottom: 0;
}
.sidebar-summary-btn {
  padding-top: 10px;
}
.sidebar-summary-btn .btn {
  margin-top: 0;
  display: block;
}

/* Menu de tabs en hotel */
.nav-hotel {
  margin-top: 15px;
}
.nav-tabs > li {
  margin-bottom: -1px !important;
}
.nav-tabs > li:first-child {
  margin-left: 1em !important;
}

/* Dropdown menu agencias  (Copiado de Bootstrap, para que el dropdown funcione en el checkout) --- */
.dropdown {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  _width: 160px;
  padding: 4px 0;
  margin: 0;
  list-style: none;
  background-color: #ffffff;
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  text-indent: -99999px;
  vertical-align: top;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000000;
  opacity: 0.3;
  filter: alpha(opacity=30);
  content: "\2193";
}

/* Dropdown menu agencias --- */
#agencyBar .dropdown ul {
  display: none;
}
#agencyBar .dropdown:hover ul {
  display: block;
}
#agencyBar .agencyOption .dropdown-menu a {
  border-left: none;
  border-right: none;
}
#agencyBar .dropdown a .caret {
  border-top-color: #fff;
}

#agencyBar .dropdown-menu a {
  color: #06c;
  line-height: 18px;
  padding: 3px 15px;
  white-space: nowrap;
  font-size: 13px;
  text-shadow: none;
  border-left: none;
  border-right: none;
}
#agencyBar .dropdown-menu a:hover {
  background-color: #feffff;
}

/* Fix para no mostrar capacidad de habitaciones caso 586337 */
.rooInf td:first-child {
  display: none;
}

/* Oculta capacidad en listado de hoteles, sólo funciona en IE 8+, Chrome, Safari, FF */
#rooAme tr:nth-child(4),
#rooAme tr:nth-child(5),
#rooAme tr:nth-child(6) {
  display: none;
}

/* Correcciones cruceros*/
#deckplanControls .ap_superCabinTypeToggler {
  width: 45%;
  margin-left: 5%;
}

#deckplanControls .nm {
  width: 50%;
}

li .stepText {
  font-size: 13px !important;
}

/*margenes en paginado de addons*/
#addon_content .light {
  margin: 10px 0 5px 0;
}

/* Desglose de tarifas */
#divComplete .padRi th {
  font-size: 12px;
  font-weight: bold;
}

/*Agrega un triángulo a un lado de los precios de circuitos en la pestaña "Tarifas y disponibilidad"*/
a.rateList {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  text-align: right;
}

a.rateList:after {
  display: inline-block;
  width: 0;
  height: 0;
  text-indent: -99999px;
  vertical-align: middle;
  border-left: 5px dotted rgba(255, 255, 255, 0);
  border-right: 5px dotted rgba(255, 255, 255, 0);
  border-top: 5px solid #0066cc;
  opacity: 1;
  filter: alpha(opacity=100);
  content: "";
  margin-left: 5px;
  margin-top: 6px;
}
/*Viñetas para "servicios incluidos"*/
.detPop .modal-header {
  border: none;
}
.detPop .modal-body ul {
  list-style-type: disc;
  line-height: 2;
}
/*atributos para "descuento especial" */
.regular-price {
  text-decoration: line-through;
  font-weight: normal;
  color: #999;
  font-size: 12px;
}
.rates .regular-price {
  margin: 0px 15px 3px;
  text-decoration: line-through;
}
.discount-price {
  margin: 0;
}
.boxPri .regular-price,
.boxPri .special-message,
.flight-list .regular-price {
  margin-bottom: 5px;
}
a.botAzu {
  margin-top: 5px;
}

/*Estilos listado tours*/
.activity li.ratefrom {
  clear: both;
  width: auto;
}
.activity .regular-price,
.activity .discount-price {
  margin: 0 5px;
}
.activity .discount-price {
  font-weight: bold;
  font-size: 14px;
}

/**/

/* Nuevo grid ----- */
.grid-row {
  margin-left: -15px;
  margin-right: -15px;
}
.grid-row,
.grid-row *,
.grid-row *:after,
.grid-row *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.66666667%;
}

.col-10 {
  width: 83.33333333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.66666667%;
}

.col-7 {
  width: 58.33333333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.66666667%;
}

.col-4 {
  width: 33.33333333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.66666667%;
}

.col-1 {
  width: 8.33333333%;
}

/*Font icons ----- */
@font-face {
  font-family: "ptw-icons";
  src: url("../fonts/ptw-icons.eot?whfet5");
  src:
    url("../fonts/ptw-icons.eot?#iefixwhfet5") format("embedded-opentype"),
    url("../fonts/ptw-icons.woff?whfet5") format("woff"),
    url("../fonts/ptw-icons.ttf?whfet5") format("truetype"),
    url("../fonts/ptw-icons.svg?whfet5#ptw-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="ptw-icon-"]:before,
[class*=" ptw-icon-"]:before,
.icon {
  font-family: "ptw-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cars:before {
  content: "\e006";
}

.icon-heart:before {
  content: "\2665";
}

.icon-heart-empty:before {
  content: "\2661";
}

.icon-cruises:before,
.ptw-icon-cruises:before {
  content: "\e601";
}

.icon-star-half:before {
  content: "\2606";
}

.icon-star:before {
  content: "\2605";
}

.icon-video:before {
  content: "\e604";
}

.icon-list:before {
  content: "\e605";
}

.icon-search:before {
  content: "\e606";
}

.icon-opinion:before {
  content: "\e607";
}

.icon-opinion-half:before {
  content: "\e608";
}

.icon-opinion-empty:before {
  content: "\e609";
}

.icon-depart:before,
.ptw-icon-depart:before {
  content: "\e60a";
}

.icon-return:before,
.ptw-icon-return:before {
  content: "\e60b";
}

.icon-offertag:before,
.ptw-icon-discount:before {
  content: "\e60d";
}

.icon-check:before {
  content: "\e60e";
}

.icon-info:before {
  content: "\e60f";
}

.icon-hotels:before,
.ptw-icon-bed:before {
  content: "\e610";
}

.icon-buses:before {
  content: "\e611";
}

.icon-calendar:before {
  content: "\e612";
}

.icon-user:before,
.ptw-icon-people:before {
  content: "\e613";
}

.icon-uparrow:before,
.ptw-icon-chevup:before {
  content: "\25b2";
}

.icon-downarrow:before,
.ptw-icon-chevdown:before {
  content: "\25bc";
}

.icon-transfers:before,
.ptw-icon-traslado:before {
  content: "\e616";
}

.icon-building:before {
  content: "\e617";
}

.icon-offer:before {
  content: "\e618";
}

.icon-plus:before {
  content: "\2b";
}

.icon-close:before {
  content: "\2716";
}

.icon-flights:before,
.ptw-icon-airplane-up:before {
  content: "\e61b";
}

.icon-tours:before {
  content: "\e61c";
}

.icon-mapmarker:before {
  content: "\e61d";
}

.icon-suitcase:before {
  content: "\e620";
}

.icon-circuits:before {
  content: "\e61d";
}

.icon-packages:before {
  content: "\e620";
}

.icon-shield:before,
.ptw-icon-shield:before {
  content: "\26e8";
}

[class^="ptw-icon-stars"]:before {
  color: #fc0;
  white-space: nowrap;
}

.ptw-icon-stars-Half:before {
  content: "\2606";
}

.ptw-icon-stars-one:before {
  content: "\2605";
}

.ptw-icon-stars-oneHalf:before {
  content: "\2605 \2606";
}

.icon-twostar:before,
.ptw-icon-twostar:before,
.ptw-icon-stars-two:before {
  content: "\2605 \2605";
}

.icon-twohalf:before,
.ptw-icon-twohalf:before,
.ptw-icon-stars-twoHalf:before {
  content: "\2605 \2605 \2606";
}

.icon-threestar:before,
.ptw-icon-threestar:before,
.ptw-icon-stars-three:before {
  content: "\2605 \2605 \2605";
}

.icon-threehalf:before,
.ptw-icon-threehalf:before,
.ptw-icon-stars-threeHalf:before {
  content: "\2605 \2605 \2605 \2606";
}

.icon-fourstar:before,
.ptw-icon-fourstar:before,
.ptw-icon-stars-four:before {
  content: "\2605 \2605 \2605 \2605";
}

.icon-fourhalf:before,
.ptw-icon-fourhalf:before,
.ptw-icon-stars-fourHalf:before {
  content: "\2605 \2605 \2605 \2605 \2606";
}

.icon-fivestar:before,
.ptw-icon-fivestar:before,
.ptw-icon-stars-five:before {
  content: "\2605 \2605 \2605 \2605 \2605";
}

.icon-fivehalf:before,
.ptw-icon-fivehalf:before,
.ptw-icon-stars-fiveHalf:before {
  content: "\2605 \2605 \2605 \2605 \2605 \2606";
}

.icon-sixstar:before,
.ptw-icon-sixstar:before,
.ptw-icon-stars-six:before {
  content: "\2605 \2605 \2605 \2605 \2605 \2605";
}

.icon-twostar,
.icon-twohalf,
.icon-threestar,
.icon-threehalf,
.icon-fourstar,
.icon-fourhalf,
.icon-fivestar,
.icon-fivehalf,
.icon-sixstar,
.ptw-icon-twostar,
.ptw-icon-twohalf,
.ptw-icon-threestar,
.ptw-icon-threehalf,
.ptw-icon-fourstar,
.ptw-icon-fourhalf,
.ptw-icon-fivestar,
.ptw-icon-fivehalf,
.ptw-icon-sixstar {
  white-space: nowrap;
}
.icon-twostar,
a .icon-twostar,
a:hover .icon-twostar,
.icon-twohalf,
a .icon-twohalf,
a:hover .icon-twohalf,
.icon-threestar,
a .icon-threestar,
a:hover .icon-threestar,
.icon-threehalf,
a .icon-threehalf,
a:hover .icon-threehalf,
.icon-fourstar,
a .icon-fourstar,
a:hover .icon-fourstar,
.icon-fourhalf,
a .icon-fourhalf,
a:hover .icon-fourhalf,
.icon-fivestar,
a .icon-fivestar,
a:hover .icon-fivestar,
.icon-fivehalf,
a .icon-fivehalf,
a:hover .icon-fivehalf,
.icon-sixstar,
a .icon-sixstar,
a:hover .icon-sixstar,
.ptw-icon-twostar,
a .ptw-icon-twostar,
a:hover .ptw-icon-twostar,
.ptw-icon-twohalf,
a .ptw-icon-twohalf,
a:hover .ptw-icon-twohalf,
.ptw-icon-threestar,
a .ptw-icon-threestar,
a:hover .ptw-icon-threestar,
.ptw-icon-threehalf,
a .ptw-icon-threehalf,
a:hover .ptw-icon-threehalf,
.ptw-icon-fourstar,
a .ptw-icon-fourstar,
a:hover .ptw-icon-fourstar,
.ptw-icon-fourhalf,
a .ptw-icon-fourhalf,
a:hover .ptw-icon-fourhalf,
.ptw-icon-fivestar,
a .ptw-icon-fivestar,
a:hover .ptw-icon-fivestar,
.ptw-icon-fivehalf,
a .ptw-icon-fivehalf,
a:hover .ptw-icon-fivehalf,
.ptw-icon-sixstar,
a .ptw-icon-sixstar,
a:hover .ptw-icon-sixstar {
  color: #e1be60;
}

.icon-rateHalf:before {
  content: "\e608 \e609 \e609 \e609 \e609";
}
.icon-rateOne:before {
  content: "\e607 \e609 \e609 \e609 \e609";
}
.icon-rateOnehalf:before {
  content: "\e607 \e608 \e609 \e609 \e609";
}
.icon-rateTwo:before {
  content: "\e607 \e607 \e609 \e609 \e609";
}
.icon-rateTwohalf:before {
  content: "\e607 \e607 \e608 \e609 \e609";
}
.icon-rateThree:before {
  content: "\e607 \e607 \e607 \e609 \e609";
}
.icon-rateThreehalf:before {
  content: "\e607 \e607 \e607 \e608 \e609";
}
.icon-rateFour:before {
  content: "\e607 \e607 \e607 \e607 \e609";
}
.icon-rateFourhalf:before {
  content: "\e607 \e607 \e607 \e607 \e608";
}
.icon-rateFive:before {
  content: "\e607 \e607 \e607 \e607 \e607";
}

.icon-rateHalf,
.icon-rateOne,
.icon-rateOnehalf,
.icon-rateTwo,
.icon-rateTwohalf,
.icon-rateThree,
.icon-rateThreehalf,
.icon-rateFour,
.icon-rateFourhalf,
.icon-rateFive {
  white-space: nowrap;
  color: #5180ad;
}

/* Ajustes iconos */
.icon-offertag:before,
.icon-offer:before,
.ptw-icon-offertag:before,
.ptw-icon-offer:before {
  color: #080;
}

button.icon.icon-calendar {
  font-size: 2em;
  border: none;
  background: none;
}

td.ratedet .caret {
  margin: 0.5em 0 0 3px;
}

#rowPromotions .icon-offertag {
  font-size: 125%;
  margin-right: 3px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 760px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}
.popover .arrow {
  border-width: 6px;
}
.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: -6px;
  left: 50%;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.popover .arrow:after {
  content: " ";
  border-width: 5px;
  top: 1px;
  margin-left: -5px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
}
.popover-title .close-link {
  float: right;
  font-size: 12px;
  opacity: 0.5;
}
.popover-title .close-link,
.popover-title .close-link:hover {
  text-decoration: none;
}
.popover-title .close-link:hover {
  opacity: 1;
}

.popover-content {
  padding: 9px 14px;
}

.popover .close {
  margin: 8px 14px;
}
.popover .popover-title .close {
  margin: 0;
}

/* Estilos para páginas de Destinos ----- */
.destination-page h2 {
  margin: 10px 0;
}

.destination-list-item {
  margin: 10px 0;
  overflow: visible;
}
.destination-list-item .ptw-dguides-pic,
.destination-list-item .destination-list-item-content {
  float: left;
}
.destination-list-item .ptw-dguides-pic {
  width: 30%;
}
.destination-list-item .destination-list-item-content {
  width: 70%;
  padding-left: 10px;
  max-height: 90px;
  overflow: hidden;
}
.destination-list-item .destination-list-item-content h3 {
  margin-bottom: 5px;
  margin-top: 0;
}
div.destination-list-item img {
  margin-left: 0;
}
.destination-listing-content .destination-list-item:nth-child(odd) {
  clear: both;
}

.destination-header h1,
.destination-listing h1 {
  margin: 20px 0;
}

.destination-banner {
  position: relative;
  margin-bottom: 20px;
  max-height: 300px;
  overflow: hidden;
}
.destination-banner img {
  width: 100%;
}

.destination-excerpt {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  font-size: 150%;
  padding: 20px;
}
.destination-excerpt p {
  margin: 0;
}

.destination-promo-list *,
.destination-promo-list *:after,
.destination-promo-list *:before {
  box-sizing: border-box;
}
.destination-promo-list li:nth-child(odd) {
  background: #f3f3f3;
}

.promo-item {
  overflow: hidden;
}
.promo-item a {
  color: #333;
}
.promo-item span,
.promo-item strong {
  display: block;
}
.promo-item strong {
  font-size: 130%;
}

.promo-item-info,
.promo-item-price {
  float: left;
  width: 50%;
  padding: 7px;
}

.promo-item-price {
  text-align: right;
}

.destination-products {
  clear: both;
  margin-top: 20px;
}

.links-grid {
  margin: 0 -5px;
  padding: 0;
}
.links-grid *,
.links-grid *:after,
.links-grid *:before {
  box-sizing: border-box;
}
.links-grid li {
  float: left;
  padding: 5px;
  width: 25%;
}
.links-grid li a {
  display: block;
  background: #f3f3f3;
  text-decoration: none;
  padding: 10px;
  color: #333;
}
.links-grid li a:hover {
  text-decoration: none;
  background: #def;
}
.links-grid li a > img,
.links-grid li a > span,
.links-grid li a > strong {
  display: block;
}
.links-grid li a > img {
  width: 100%;
  margin-bottom: 10px;
}
.links-grid li a .note {
  color: #666;
}

.links-grid-title {
  font-size: 140%;
  margin-bottom: 5px;
}

.hotel-links-grid .links-grid-title {
  min-height: 2em;
}

/* Estilos para páginas de Estilos de viaje ----- */
.travelStyles-page h2 {
  padding: 0;
  margin: 20px 0 10px 0;
}

.hotel-offers-grid {
  margin: 0 -5px;
  padding: 0;
  overflow: hidden;
}
.hotel-offers-grid *,
.hotel-offers-grid *:after,
.hotel-offers-grid *:before {
  box-sizing: border-box;
}
.hotel-offers-grid li {
  float: left;
  width: 25%;
  padding: 5px;
}
.hotel-offers-grid li .hotel-pic,
.hotel-offers-grid li .hotel-title,
.hotel-offers-grid li .hotel-offer-info,
.hotel-offers-grid .label-cont {
  display: block;
  padding: 0 10px;
  margin: 0;
  background: #f3f3f3;
}
.hotel-offers-grid li .hotel-title,
.hotel-offers-grid li .hotel-offer-info {
  padding-bottom: 10px;
}
.hotel-offers-grid li .hotel-pic {
  width: 100%;
  padding: 10px;
}
.hotel-offers-grid li h3.hotel-title {
  line-height: 1em;
  min-height: 2.5em;
}

.hotel-offer-item .hotel-offer-info > span,
.hotel-offer-item .hotel-offer-info > strong,
.destination-offers-grid > span,
.destination-offers-grid > strong {
  line-height: 1.2em;
  display: block;
}
.hotel-offer-item .hotel-offer-info .note,
.destination-offers-grid .note {
  color: #666;
  margin-top: 3px;
}

.hotel-title {
  font-size: 140%;
}

strong.price {
  font-size: 150%;
}

/* Calendario de ofertas */
.calendar-topbar {
  overflow: hidden;
}

.calendar-header {
  background: #f3f3f3;
  position: relative;
}

.calendar-topbar {
  background: #eee;
  padding: 10px;
}
.calendar-topbar .calendar-title {
  float: left;
  font-size: 130%;
  line-height: 30px;
}
.calendar-topbar .new-search {
  float: right;
}
.calendar-topbar .new-search .btn {
  margin-top: 0;
}

.offer-info {
  padding: 10px;
}
.offer-info p {
  margin: 10px 0 5px 0;
}
.offer-info ul {
  margin: 0 0 10px 1.5em;
}
.offer-info li {
  list-style: square;
}

.offer-destinations {
  font-size: 130%;
  margin-top: 0;
}

.nav-calendar .nav {
  margin-top: -3em;
  width: 100%;
}
.nav-calendar .nav li {
  float: right;
}

.new-search-widget {
  position: absolute;
  width: 350px;
  background: #fff;
  border: 1px solid #ddd;
  right: 0;
}

.new-search-header {
  font-size: 130%;
  line-height: 50px;
  padding: 0 10px;
  background: #eee;
}
.new-search-header .close-icon {
  float: right;
  text-decoration: none;
}

.promo-calendar {
  margin: 15px 0;
  width: 100%;
  line-height: 1.25;
}
.promo-calendar .promoRate {
  font-size: 18px;
}
.promo-calendar .day,
.promo-calendar .cell .fr {
  float: left;
  font-size: 12px;
  color: #888;
  margin: -1em;
}
.promo-calendar .ap_noSelect,
.promo-calendar .noRate {
  padding: 1.25em;
  text-align: right;
}

.calSelectedHotel {
  font-size: 1em;
}

.calSelectedHotel h1 {
  font-size: 2em;
}

.rateCalendar {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.rateCalendar td {
  overflow: hidden;
}

.cell {
  border: 1px solid #e6e6e6;
  background: #fff;
  text-align: center;
  overflow: hidden;
}

.cell a.nrml,
.cell a.nrml:hover {
  display: block;
  padding: 1.25em;
  padding-bottom: 2em;
  text-align: right;
  text-decoration: none;
  color: #333;
  height: 100%;
}
.cell a.nrml em,
.cell a.nrml:hover em {
  font-style: normal;
}

.cell .month {
  display: none;
}

.promo-calendar .cell .fr {
  float: none;
}

.promo-calendar th {
  padding: 0.5em;
}

.calWeekdays th {
  font-weight: normal;
  background: #eee;
  color: #999;
  font-size: x-small;
  text-align: center;
  text-transform: uppercase;
}

.promo-calendar .monthBorderTop {
  border-top: 2px solid #999 !important;
}

.promo-calendar .monthBorderLeft {
  border-left: 2px solid #999 !important;
}

.promo-calendar tr .calMonthIndex + .monthBorderLeft {
  /* Evita borde izquierdo cuando cae en domingo */
  border-left: 1px solid #e6e6e6 !important;
}

.promo-calendar .calMonthIndex {
  font-size: large;
  font-weight: bold;
  text-transform: lowercase;
}

.promo-calendar .noSelect {
  background: #eeeeee;
  border: 1px solid #d6d6d6;
  height: 44px;
  width: 46px;
}

.promo-calendar .noSelect a {
  color: #999;
}

.pt {
  padding: 1em 0 0;
}

/* CALENDARIO adaptado para mostrar ofertas precotizadas */
.promo-calendar.rateIncludes .product {
  background: rgba(237, 21, 86, 0.08);
  padding: 0.25em 0.5em;
  border-radius: 10px;
  display: inline-block;
  vertical-align: baseline;
}

.promo-calendar.rateIncludes .icon {
  height: 20px;
  margin-top: -2px;
}

.promo-calendar.rateIncludes .plus {
  color: #ed1556;
  font-size: 1.5em;
  vertical-align: middle;
}

table.pOverview {
  min-width: 580px;
  /* ancho de la tabla de detalles de paquete */
}

.calTabs {
  text-align: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #b2d5f1;
}

.calTabs li {
  display: inline;
}

.calTabs li a {
  padding: 5px 15px;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  color: #003767;
  background: #dfeefa;
  box-shadow: 0 -3px 3px -2px #ccd inset;
  -moz-box-shadow: 0 -2px 3px -2px #ccd inset;
  text-shadow: 0 1px 0 #fff;
  line-height: 1.5;
}

.calTabs li a:hover {
  color: #ed1556;
}

.calTabs .active {
  background: #fff;
  border: 1px solid #b2d5f1;
  border-bottom-color: transparent;
  color: #ed1556;
  margin-bottom: -1px;
  box-shadow: 0 3px 3px -3px #e0eaea inset;
  -moz-box-shadow: 0 3px 3px -3px #e0eaea inset;
  font-weight: bold;
}

.bRate-1 {
  background: #c4f296;
}

.bRate-2,
.bRate-3 {
  background: #e2f7ca;
}

.promo-calendar.bRate-1 .promoRate em {
  color: #ed1556;
  font-weight: bold;
  font-style: normal;
}

.promo-calendar.noAvailability {
  margin: 1em 0.5em !important;
  font-style: italic;
  text-align: right;
  color: #999;
  display: block;
}

.promo-calendar.rateCalendar .dayNum {
  line-height: 1.2;
  padding: 0.15em 0.25em;
  color: #999;
  text-align: left;
  font-size: 0.7em;
  display: block;
}

.promo-calendar.regRate {
  font-size: 0.75em;
  line-height: 1.15em;
  color: #777;
  display: none;
}

.promo-calendar.promoRate {
  display: block;
}

.promo-calendar.promoRate em {
  font-style: normal;
}

.promo-calendar.hotName {
  margin: 1em 0;
  font-size: 0.75em;
  display: block;
  color: #999;
}

.promo-calendar.calStarOn {
  color: #ff9a16;
}

.cell .mSprite {
  opacity: 0.35;
}

.cell a:hover .mSprite {
  opacity: 1;
}

.promo-calendar .samePackDaysFirst,
.promo-calendar .samePackDaysInner,
.promo-calendar .samePackDaysLast {
  background-color: #def;
  position: relative;
}
.promo-calendar .samePackDaysFirst .bRate-1,
.promo-calendar .samePackDaysFirst .bRate-2,
.promo-calendar .samePackDaysFirst .bRate-3,
.promo-calendar .samePackDaysInner .bRate-1,
.promo-calendar .samePackDaysInner .bRate-2,
.promo-calendar .samePackDaysInner .bRate-3,
.promo-calendar .samePackDaysLast .bRate-1,
.promo-calendar .samePackDaysLast .bRate-2,
.promo-calendar .samePackDaysLast .bRate-3 {
  background: #def;
}

.promo-calendar .samePackDaysFirst .promoRate:after {
  content: " ";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.promo-calendar .samePackDaysFirst:before,
.promo-calendar .samePackDaysLast:before,
.promo-calendar .samePackDaysInner:before {
  display: block;
  content: " ";
  background: #06c;
  height: 2px;
  position: absolute;
  bottom: 17px;
}

.promo-calendar .samePackDaysInner:before {
  width: 100%;
  left: 0;
}

.promo-calendar .samePackDaysFirst:after,
.promo-calendar .samePackDaysLast:after {
  display: block;
  content: " ";
  background: #06c;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  position: absolute;
  bottom: 15px;
  left: 50%;
}
.promo-calendar .samePackDaysFirst:before,
.promo-calendar .samePackDaysLast:before {
  width: 50%;
}

.promo-calendar .samePackDaysFirst:before {
  left: 50%;
}

.promo-calendar .samePackDaysLast:before {
  left: 0;
}

.promo-calendar .samePackDaysInner *,
.promo-calendar .samePackDaysLast * {
  visibility: hidden;
}

.promo-calendar .samePackDaysInner .dayNum .fr,
.promo-calendar .samePackDaysLast .dayNum .fr,
.promo-calendar .samePackDaysInner .dayNum .day,
.promo-calendar .samePackDaysLast .dayNum .day {
  visibility: visible;
}

.rateIncludes {
  margin: 1em;
}

.calendar-popover {
  min-width: 560px;
}
.calendar-popover h4 {
  margin: 0;
}

.offer-price-details {
  text-align: center;
  margin-bottom: 10px;
  background: #def;
  padding-top: 10px;
  margin: -8px 0 0 0;
}
.offer-price-details .total-price {
  font-size: 120%;
  line-height: 1.5em;
  padding-bottom: 10px;
}
.offer-price-details .total-price strong {
  font-size: 135%;
}

.offer-services-details .service-details {
  margin-bottom: 15px;
}
.offer-services-details p {
  margin: 0;
}
.offer-services-details .note {
  margin: 0;
  color: #666;
  font-size: 85%;
}
.offer-services-details .service-icon {
  float: left;
  width: 32px;
}
.offer-services-details .service-icon .icon-hotels {
  font-size: 16px;
}
.offer-services-details .service-data {
  margin-left: 32px;
}
.offer-services-details .airline-logo {
  text-align: center;
  display: block;
  float: left;
  width: 50px;
}
.offer-services-details .flight-info {
  margin-left: 55px;
}

/* Aviso de privacidad en la parte del itinerrio */
#avisoPriv {
  border: 1px #eeeeee solid;
  margin: 10px;
  padding: 10px;
  width: 710px;
  float: right;
  border-radius: 0.5em 0.5em 0.5em 0.5em;
}

/* Certificado de servicios de viaje */
.cuerpo li {
  float: left;
  width: 25%;
}

#content.certificado,
#Content.certificado {
  padding: 0;
}
.certificado .header {
  position: relative;
}
.certificado .closelink,
.certificado h1 {
  position: absolute;
  z-index: 1000;
}
.certificado .closelink {
  right: 0;
}
.certificado .closelink a {
  color: #003f67;
}
#activityPopup #head h1.certificado {
  background-color: #003780;
  color: #fff;
  display: block;
  font-size: 18px;
  height: 18px;
  letter-spacing: 0.03em;
  margin: 40px 0 0;
  padding: 10px 25px;
  text-transform: uppercase;
}
.certificado .cuerpo {
  padding: 0 10px;
}

.cuerpo {
  max-width: 750px;
}

.cuerpo h3 {
  color: #003f67;
  font-size: 18px;
  font-weight: bold;
  margin-left: 25px;
}
.cuerpo ul {
  margin: 0;
  overflow: auto;
}
.cuerpo li {
  float: left;
  width: 25%;
}
.cuerpo li img,
.cuerpo li p {
  display: block;
  margin: auto;
}
.cuerpo li p {
  color: #003f67;
  font-size: 1.2em;
  margin: 10px auto 15px;
  min-height: 6em;
  text-align: center;
  width: 150px;
}
.cuerpo li p span {
  display: block;
}
.certificado .col-wrap {
  overflow: auto;
}
.certificado .col-6,
.col-6 {
  float: left;
  width: 45%;
}
.col-6 img {
  margin-left: 20px;
}
.col-6.sides-margin * {
  margin-left: 25px;
  margin-right: 25px;
}
#activityPopup .footer {
  background-color: #003f67;
  color: #c8d5de;
  font-size: 1.1em;
  letter-spacing: 0.01em;
  margin-top: 30px;
  overflow: auto;
  padding: 0 25px 20px;
}
#activityPopup .col-wrap {
  overflow: auto;
}
#activityPopup .footer .col-wrap .col-6 * {
  margin-left: 25px;
}
#activityPopup .footer h3 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-left: 25px;
}
#activityPopup .footer ul li {
  padding-bottom: 5px;
  padding-left: 15px;
}
#activityPopup .footer ul.tel {
  background: url("//3.cdnpt.com/images/seguros/tel.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
}
#activityPopup .footer ul.mail {
  background: url("//3.cdnpt.com/images/seguros/mail.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  display: block;
  min-height: 15px;
}
#activityPopup .footer .col-wrap .col-6 .mail a {
  color: #c8d5de;
  margin-left: 0;
}
#phoneToggler {
  z-index: 0 !important;
}

/* Circuitos */
.circuits-searchbox .date {
  float: left;
}
#divCircuitInfo {
  line-height: 1.5;
}
#divCircuitInfo .horizontal-defs dt {
  float: left;
  width: 20%;
  margin: 0 2% 0 0;
  font-weight: normal;
  text-align: right;
  font-size: 12px;
  color: #777;
}
#divCircuitInfo .horizontal-defs dd {
  margin-left: 22%;
  margin-bottom: 0.75em;
}
#divCircuitInfo dl.horizontal-defs {
  float: none;
  width: 500px;
}
.inactiveAgent {
  float: right;
  margin: 10px;
  color: #333;
}
#divCircuitInfo .span5 {
  width: 280px;
  float: left;
}
#divCircuitInfo .span4 {
  width: 220px;
}
#divCircuitInfo .rateSummary {
  float: right;
  font-size: 90%;
  color: #555;
  background: #fff;
  border: 1px solid #eee;
}
#divCircuitInfo .rateSummary td,
.rateSummary th {
  padding: 3px 5px;
}
#divuitInfoCirc .rateSummary th {
  text-align: left;
  color: #333;
}
#divuitInfoCirc .span4 h4 {
  padding: 3px 0;
  background: #e3e3e3;
}
#Content .sailDates a.navLeft,
#Content .sailDates a.navRight {
  font-size: 12px;
}
#Content #ptw-container .ptw-month-cal a.ap_calSel {
  width: 100%;
}
/* Seguros */
.assurance {
  background: none repeat scroll 0 0 #f7fbfc;
  border: 1px solid #cdeef6;
}

.assurance .asuredTitle,
th {
  padding: 0 0 0 10px;
}
.assurance .smallv {
  padding: 10px;
}

.assurance #chkOut h1 {
  background: none;
  padding: 0px !important;
}

.assurance textarea,
input,
select,
.flightpax select {
  margin: 5px 0;
}

table.flightpax tr.paxtype td,
table.flightpax tr.paxtype th {
  background: none repeat scroll 0 0 #f0f0f0 !important;
}

/* Información obligatoria */
.obligatoryFields {
  color: #ff0000;
}

#HotelListContainer a.prl {
  padding: 3px 3px;
}
.price li.ratefrom {
  margin-right: 5px !important;
}

#flgtlist .itemRate {
  width: 100%;
}
#flgtlist .itemRate h3.packPrice {
  margin-bottom: 0;
}

/* Meses sin intereses en "Revisar" */
.td-alert-info a {
  color: #3a87ad;
  padding: 10px 5px;
  border: 1px solid #bce8f1;
  display: block;
  border-radius: 5px;
  letter-spacing: -0.2px;
}
.td-alert-info a .tiny {
  color: #06c;
  float: right;
  font-weight: normal;
  font-size: 12px;
}
.td-alert-info img {
  display: none;
}
.advisor-note {
  font-size: 12px;
  line-height: 1.3;
}
#payPlans td {
  padding: 0.5em;
}

/* Fix de errores provocados por bus-list.css */
body {
  text-rendering: auto;
}
.busSelectionGroup .schedule {
  padding: 0;
}
.arrival-time {
  width: 17%;
}
.departure-time {
  width: 12%;
}
/* Fix de errores provocados por flights-ui.css */
.leg-holder {
  padding: 0.75em 0.5em;
}
#bestAirlines #bestAirContainer {
  width: 100%;
}
#bestAirContainer .bestAirList.viewport {
  width: 688px;
}

/*ESTILOS PARA NUEVA VISTA DE FACTURACIÓN*/
.facturas table {
  width: 100%;
}
.facturas h3 {
  margin-left: 18px;
  padding-left: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: url("//sc.cdnpt.com/travelinn/images/headlines.png") no-repeat;
}
.facturas h3.done {
  background-position: 0 -24px;
}
.facturas h3.policy {
  background-position: 0 -48px;
}
.facturas h3.policy:hover {
  background-position: 0 -72px;
}
.facturas h3.policy:hover a {
  color: #ed1556;
  text-decoration: underline;
}
.facturas tr td {
  border-bottom: 1px solid #dee1e1;
}
.facturas .borderless,
.facturas tr.borderless td {
  border: none;
}
.facturas td,
.facturas th {
  vertical-align: text-top;
  padding: 8px 3px;
}
.facturas thead tr {
  background: #dff3fd;
}
.facturas th {
  color: #4c4c4c;
  vertical-align: middle;
}
.facturas tr.message-global td {
  font-weight: normal !important;
}
.facturas .bold {
  font-weight: bold;
}
.facturas .disabled {
  color: #b7b7b7;
}
.facturas .emitidas td:first-child,
.facturas .emitidas th:first-child {
  width: 8%;
  padding-left: 10px;
}
.facturas .emitidas td:nth-child(2) {
  width: 33%;
}
.facturas .smallcaps {
  text-transform: uppercase;
  font-size: 0.9em;
}
.facturas .emitidas td:nth-child(3) {
  width: 14%;
}
.facturas .emitidas td:nth-child(4) {
  width: 20%;
}
.facturas .pendientes td:first-child,
.facturas .pendientes td:nth-child(5) {
  font-weight: bold;
}
.facturas .estado span {
  text-transform: uppercase;
  padding: 3px 0 3px 18px;
  font-size: 0.8em;
  letter-spacing: 0.01em;
}
.facturas .estado .listo {
  background: url("//sc.cdnpt.com/travelinn/images/estado.png") no-repeat 0px 1px;
  color: #00a663;
  font-weight: bold;
}

.facturas .estado .advertencia {
  text-transform: uppercase;
  padding: 0 0 0 18px;
  font-size: 0.8em;
  letter-spacing: 0.01em;
  background: url("//sc.cdnpt.com/travelinn/images/estado.png") no-repeat;
  background-position: 0px -45px;
  text-decoration: none;
  font-weight: bold;
  display: block;
  width: 100%;
  color: #ffa024;
}

.facturas .estado a .texto {
  text-transform: uppercase;
  padding: 0 0 0 18px;
  font-size: 0.8em;
  letter-spacing: 0.01em;
  background: url("//sc.cdnpt.com/travelinn/images/estado.png") no-repeat;
  background-position: 0px -14px;
  text-decoration: none;
  font-weight: bold;
  display: block;
  width: 100%;
  color: #00a4da;
}

.facturas .estado a:hover .texto {
  background: url("//sc.cdnpt.com/travelinn/images/estado.png") no-repeat;
  background-position: 0px -29px;
  color: #ed1556;
}
.facturas .estado a:hover .advertencia {
  background: url("//sc.cdnpt.com/travelinn/images/estado.png") no-repeat;
  background-position: 0px -61px;
  color: #ed1556;
}
.facturas .item {
  display: block;
  margin-left: 10px;
  margin-top: 5px;
  font-weight: normal;
}
.facturas .pendientes .contador {
  font-weight: normal;
  font-size: 0.9em;
  color: gray;
}
.facturas .pendientes td,
.facturas .pendientes th {
  padding: 8px 8px;
  text-align: right;
}
.facturas .pendientes td:first-of-type,
.facturas .pendientes th:first-of-type,
.facturas .pendientes td:last-of-type {
  text-align: left;
}
.descarga a {
  margin-right: 5px;
}
.green {
  color: #00a663;
}
.red {
  color: #de0a00;
}
.grey {
  color: #8b9595;
}
.facturas tr.ready {
  background: #e5f6f0;
}

/*facturacion: tooltip */
a.warntooltip {
  outline: none;
  width: 100%;
}
a.warntooltip strong {
  line-height: 25px;
}
a.warntooltip:hover {
  text-decoration: none;
}
a.warntooltip span.caja {
  z-index: 10;
  display: none;
  padding: 14px 20px;
  margin-top: -50px;
  margin-left: -290px;
  width: 240px;
  line-height: 16px;
}
a.warntooltip:hover span.caja {
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
  display: inline;
  position: absolute;
  color: #111;
  border: 1px solid #dca;
  background: #fffaf0;
}
.callout {
  z-index: 20;
  position: absolute;
  top: 30px;
  border: 0;
  right: -12px;
}

a.warntooltip span.caja {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-box-shadow: 5px 5px 8px #ccc;
  -webkit-box-shadow: 5px 5px 8px #ccc;
  box-shadow: 5px 5px 8px #ccc;
}

.envoice-issued .estado a .texto {
  padding: 3px 0 3px 18px !important;
}

.small {
  font-size: 12px;
  font-weight: normal;
  color: #777;
}
.success h3 {
  margin-bottom: 15px;
}
.success img {
  float: left;
  margin-right: 5px;
}

.item-status {
  padding: 0 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  font-size: 1em !important;
  margin-bottom: 5px !important;
}

.invoice-module {
  margin-bottom: 30px;
}
hr.separator-module {
  border: 0;
  height: 1px;
  margin: 60px 0;
  color: #ddd;
  background-color: #ddd;
}
.invoice-module h3 {
  display: block;
  font-weight: bold;
}
.invoice-module h3 .h3-small {
  font-size: 0.85em;
  font-weight: normal;
  padding: 0.5em 0 0.5em 0;
  letter-spacing: 0.25px;
  color: #888;
}
.invoice-module h3 .h3-icon {
  width: 19px;
  height: 21px;
  display: inline-block;
  margin-right: 3px;
  background: url("//sc.cdnpt.com/travelinn/images/invoice-icon.png") no-repeat;
  background-size: 90%;
}

.module-col-2 {
  width: 48%;
  float: left;
  padding: 5px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.example-view {
  margin-top: 5px;
}
.example-view img {
  float: left;
  margin-right: 10px;
}
.list-information li {
  margin-bottom: 10px;
}
.list-information li .data {
  display: block;
}
.alert-block {
  display: block;
  padding: 15px;
  background-color: #fff8ed;
  margin-top: 15px;
}
.alert-block img {
  float: left;
  margin-right: 10px;
  width: 8%;
}
.alert-block p {
  margin: 0;
}
.table-information .row-head {
  display: block;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.table-information .row-head a {
  float: right;
}
.table-information .row-table {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  overflow: auto;
  zoom: 1;
}
.table-information .cell-left {
  text-align: right;
  width: 25%;
  max-height: 30px;
  display: inline-block;
  margin-right: 5px;
  float: left;
}

/*Preview de la facura*/
.preview-invoice {
  border: 1px solid #ddd;
  padding: 25px;
  position: relative;
}

.head-preview {
  margin-bottom: 5px;
  display: block;
  width: 100%;
}

.head-preview a {
  float: right;
}

.top-invoice,
.middle-invoice,
.bottom-invoice {
  margin-bottom: 30px;
}

.gray-block {
  background-color: #f6f6f6;
}

.top-invoice {
  display: block;
  width: 100%;
  position: relative;
}

.top-invoice .info-invoice {
  width: 75%;
  float: left;
}

.top-invoice .info-invoice img {
  display: block;
}

.top-invoice .content-information {
  display: inline-block;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: #f6f6f6;
}

.top-invoice .content-information .list-items li {
  margin: 0;
}

.top-invoice .gray-block {
  width: 20%;
  height: 215px;
  float: right;
}

.invoice-table {
  width: 100%;
}
.invoice-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.invoice-table .tr-description {
  padding-bottom: 15px;
}
.invoice-table .tr-description td ul {
  margin: 15px 0 0 0;
}

.invoice-table .tr-description td ul li {
  margin: 0px;
}

.invoice-table tr th {
  font-size: 12px;
}
.invoice-table tr td,
.invoice-table tr th {
  padding: 10px;
}

.invoice-table .list-breakdown {
  text-align: right;
}

.invoice-table .list-breakdown li {
  margin-bottom: 10px;
}

.invoice-table .list-breakdown .data {
  min-width: 70px;
  display: inline-block;
}

.bottom-invoice .rfc-document {
  display: inline-block;
  width: 20%;
  float: left;
}

.bottom-invoice .rfc-document .rfc-head {
  height: 35px;
  width: 100%;
  display: inline-block;
  background-color: #4d4d4d;
}

.bottom-invoice .rfc-document .rfc-body {
  width: 100%;
  height: 150px;
  display: inline-block;
  background-color: #f6f6f6;
  position: relative;
}

.bottom-invoice .rfc-document .circle {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  background-color: #dbdbdb;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottom-invoice .gray-block {
  float: right;
  display: block;
  width: 78%;
  height: 185px;
  background-color: #f6f6f6;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}
.small {
  font-size: 12px;
  font-weight: normal;
  color: #777;
}

.facturas .emitidas td:nth-child(6) {
  width: 10%;
}
/* Fix autocomplete de cotizador de autobuses */
.pt-customJqueryUi .ui-menu .name {
  float: none;
  margin: 0;
  width: auto;
}
.pt-customJqueryUi .ui-menu .location {
  text-indent: 0 !important;
}

/* Pasos en autobuses */
.multi-steps {
  margin-bottom: 10px;
}
.multi-steps .single-step {
  height: 25px;
  background: #ddd;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 0 0 1%;
}
.multi-steps .single-step.current-step {
  background: #999;
}
.multi-steps.two-steps .single-step {
  width: 48.5%;
}
.multi-steps a:visited {
  color: #fff;
}
.multi-steps .single-step .step-num {
  background: #fff;
  color: #ddd;
  border-radius: 10px;
  width: 20px;
  height: 19px;
  display: inline-block;
  text-align: center;
  padding-top: 1px;
  margin-top: -1px;
  margin-right: 3px;
}
.multi-steps .single-step.middle-step:after,
.multi-steps .single-step.first-step:after,
.multi-steps .single-step.middle-step:before,
.multi-steps .single-step.last-step:before {
  width: 0;
  height: 0;
  border-width: 16px 0 16px 16px;
  border-style: solid;
  content: " ";
  padding: 0;
}
.multi-steps .single-step.middle-step:after,
.multi-steps .single-step.first-step:after {
  border-color: transparent transparent transparent #ddd;
  margin-top: -27px;
  float: right;
  margin-right: -16px;
  z-index: 0;
  position: relative;
}
.multi-steps .single-step.middle-step:before,
.multi-steps .single-step.last-step:before {
  border-color: transparent transparent transparent #fff;
  margin-top: -8px;
  margin-left: -8px;
  float: left;
  margin-right: 7px;
}
.multi-steps .single-step.middle-step.current-step:after,
.multi-steps .single-step.first-step.current-step:after {
  border-left-color: #999;
}
.multi-steps .current-step .step-num {
  color: #999;
}
#conTit {
  font-size: 12px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #eee;
  padding: 3px;
  background: #f9f9f9 url(../images/spritesmaster6.gif) 0 -633px repeat-x;
}
#top h1 span.smallv,
#top h1 span.higLit {
  padding: 0;
}
table.bgbottom > tbody > tr > td {
  padding: 7px;
}
table.bgbottom td {
  vertical-align: middle;
}

.alert.alert-warning {
  padding: 5px 10px;
}

/* Checkout Autobuses */
#chkOut #flightpax .paxtitles input,
#chkOut #flightpax .paxtitles select {
  display: block;
  margin-right: 10px;
}
#main #divPromotions a.cloWha {
  background: none;
}

/* Estilos para traslados en paquetes multidestino */
.leg .icon {
  font-size: 18px;
}
.deleteLink,
.detailsLink {
  font-size: 12px;
}
.deleteLink {
  display: block;
}
.btnAdded {
  display: inline-block;
  padding: 1px 10px;
  background: #eee;
  color: #888;
  font-weight: bold;
  border: 1px solid #888;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  text-decoration: none;
  cursor: not-allowed;
}
.btnAdded:hover {
  text-decoration: none;
  color: #888;
}
.rate-group {
  background: white !important;
  padding: 10px;
  margin-top: 10px !important;
}
.listing {
  margin: 0;
  padding: 0;
  background: red;
}
.listingItem {
  margin: 0;
  padding: 10px 0 0 0;
  overflow: hidden;
  border-width: 0 0 1px;
  background: #ffffff;
}
.listingItem:first-child {
  margin-top: 10px;
}
.listingItem:last-child {
  height: 50px;
  overflow: hidden;
}
.listingItem-figure {
  margin-bottom: 0;
  float: left;
}
.listingItem-figure img {
  margin: 0;
  padding: 0;
  margin-right: 10px;
  display: block;
  overflow: hidden;
}
.listItem-primaryBlock {
  display: block;
  float: left;
  min-width: 26em;
}
.listItem-primaryBlock + .listItem-primaryBlock {
  min-width: 10em;
}
.listItem-secondaryBlock {
  text-align: right;
  display: block;
  float: right;
}
.leg-type--truncate {
  width: 535px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.alert--warning {
  padding: 2px 5px;
  color: #615d46 !important;
  background-color: #fcf8e3;
  box-shadow: 0 0 3px #cccc9a;
}
/*Texto*/
.transfer-type {
  font-weight: normal;
  font-size: 14px;
}
.primaryText {
  font-weight: bold;
  font-size: 17px;
  line-height: 18px;
}
.secondaryText {
  font-size: 13px;
  max-width: 10.76923077em;
  white-space: nowrap;
  color: #6b6b6b;
}
/*Checkout*/
.checkProducts-table {
  width: 100%;
}
.checkProducts-title td {
  background: #eaf2f9;
  padding: 5px 0;
}
.checkProducts-logo {
  width: 100px;
}
.checkProducts-description td:nth-child(2) {
  width: 40%;
}
/*Update*/
.primaryText a {
  font-weight: normal !important;
}

.messageNotAvailable {
  margin: 10px 0;
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #fcf8e3;
  color: #ce7059;
  font-size: 14px;
  font-weight: bold;
}

.rate-group.is-disabled {
  background: #f5f5f5 !important;
}

.rate-group.is-disabled h2,
.rate-group.is-disabled i,
.rate-group.is-disabled span {
  color: #999 !important;
  text-shadow: 0 1px 0 #fff !important;
}

.rate-group.is-disabled .gray-gradient {
  background: #e6e6e6 !important;
}

/**/
.hotPagCont .padding .imageLink {
  margin-right: 10px;
  display: block;
}
#divComplete .padRi td {
  padding: 0;
}
ul.activity li.img img {
  max-width: 70px;
}

.ap_summaryPrepaidGratuities0 {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #fff;
  padding: 7px;
  background-color: #f3f3f3;
}
/*checkout trastaldos*/

/* iconos calendarios */
.date-toggler {
  margin-top: 5px;
}
span.first-child button {
  margin-top: 5px;
}

/*Ajuste de disponibilidad de camarotes en la vista gráfica para seleccionarlo*/
#main #legendul {
  width: 85px;
}
#summary td.right {
  text-align: left;
  line-height: 1em;
  padding: 5px 0;
}
#summary .payment-info {
  padding: 7px;
}

.hotImg.addonicon.transfer {
  background: url("//sc.cdnpt.com/general/images/000151_th.jpg");
  background-position: center center;
  background-size: cover;
}

/* jquery-ui states and images */
.ui-icon {
  width: 16px;
  height: 16px;
  background-image: url("//sc.cdnpt.com/general/images/ui-icons_222222_256x240.png") /*{iconsContent}*/;
}
.ui-widget-content .ui-icon {
  background-image: url("//sc.cdnpt.com/general/images/ui-icons_222222_256x240.png") /*{iconsContent}*/;
}
.ui-widget-header .ui-icon {
  background-image: url("//sc.cdnpt.com/general/images/ui-icons_222222_256x240.png") /*{iconsHeader}*/;
}
/*bootstrap span fix para espana */
html[class*="espana"] {
  float: none;
  margin: 0;
}

/**/
.view-more {
  font-size: 12px;
  vertical-align: text-top;
}
a.pop {
  width: auto;
}
/**/

/* FAMILIAS TARIFARIAS */

#flgtlist {
  /* Mensaje para vuelos de regreso */
  .alert {
    padding: 5px 10px;
    border: 1px solid transparent;
    border-radius: 3px;
    margin-bottom: 5px;
  }
  .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }
  .alert-info p {
    margin: 0.5em 0;
  }
  /* Vuelo con familias tarifarias */
  .flight-group {
    position: relative;
    background: #f9f9f9;
    margin: 0.75rem 0;
  }
  .flight-group:hover {
    background: #f6f6f6;
  }
  .flight-group:before,
  .flight-group:after {
    content: " ";
    display: table;
  }
  .flight-group:after {
    clear: both;
  }
  .flight-group-content:before,
  .flight-group-content:after {
    content: " ";
    display: table;
  }
  .flight-group-content:after {
    clear: both;
  }
  .flight-group-row:before,
  .flight-group-row:after {
    content: " ";
    display: table;
  }
  .flight-group-row:after {
    clear: both;
  }
  .flight-group-row,
  .flight-group-content {
    display: table;
    width: 100%;
  }
  .flight-group-main {
    display: table-cell;
    vertical-align: top;
    width: 77.5%;
  }
  .flight-group-secondary {
    display: table-cell;
    vertical-align: top;
    width: 25.5%;
  }
  .flight-group-secondary {
    color: #777;
  }
  .flight-grid-cell {
    display: inline-block;
    vertical-align: top;
    width: 83px;
    padding: 0.75rem 0;
    margin: 0;
    float: left;
    text-align: center;
  }
  .flight-grid-cell:nth-child(2n + 1) {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .flight-grid-header {
    padding: 0;
  }
  .flight-grid-header:before,
  .flight-grid-header:after {
    content: " ";
    display: table;
  }
  .flight-grid-header:after {
    clear: both;
  }
  .flight-grid-header-main {
    position: relative;
    min-height: 1px;
    padding-left: 12px;
    padding-right: 12px;
    padding: 0;
  }
  .flight-grid-header-main {
    float: left;
    width: 28.25%;
  }
  .flight-grid-header-secondary {
    position: relative;
    min-height: 1px;
    padding-left: 12px;
    padding-right: 12px;
    float: right !important;
    padding: 0;
    text-align: right;
  }
  .flight-grid-header-secondary {
    float: left;
    width: 71.75%;
  }
  .flight-grid-item {
    line-height: 125%;
  }
  .flight-grid-item:before,
  .flight-grid-item:after {
    content: " ";
    display: table;
  }
  .flight-grid-item:after {
    clear: both;
  }
  .flight-grid-item-row:before,
  .flight-grid-item-row:after {
    content: " ";
    display: table;
  }
  .flight-grid-item-row:after {
    clear: both;
  }
  .flight-grid-item-main {
    position: relative;
    min-height: 1px;
    padding-left: 12px;
    padding-right: 12px;
    padding: 0;
  }
  .flight-grid-item-main {
    float: left;
    width: 28.25%;
  }
  .flight-grid-item-secondary {
    position: relative;
    min-height: 1px;
    padding-left: 12px;
    padding-right: 12px;
    float: right !important;
    padding: 0;
    text-align: right;
  }
  .flight-grid-item-secondary {
    float: left;
    width: 71.75%;
  }
  .flight-fare-container {
    padding: 0.75rem;
  }
  .flight-fare-summary {
    margin: 0.75rem 0 0.25rem;
    line-height: 1.35;
  }
  .flight-fare-summary__notes {
    font-size: 12px;
  }
  .flight-fare-summary__amount {
    font-size: 20px;
    color: #464646;
  }
  .flight-fare-summary__amount.cheaper {
    color: #07c;
  }
  .flight-fare-summary__amount-currency {
    display: inline-block;
    font-size: 14px;
  }
  .flight-fare-actions .button {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    background-color: #ed1556;
    padding: 6px 12px;
    margin-bottom: 0;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
  }
  .flight-fare-actions .button:hover {
    color: #fff;
  }
  .flight-item-airline-info {
    font-size: 12px;
    font-weight: 600;
    color: #777;
    padding: 0.5rem 0.5rem 0.5rem 2.3rem;
    margin: 0.25rem 0;
  }
  .flight-item-airline-info img {
    position: absolute;
    left: 7px;
    top: 7px;
  }
  .flight-item-info {
    padding: 0.5rem;
  }
  .flight-item-schedule {
    font-size: 14px;
    margin-bottom: 0.4rem;
  }
  .flight-item-schedule__depart,
  .flight-item-schedule__trajectory-arrow,
  .flight-item-schedule__arrival {
    display: inline-block;
  }
  .flight-item-schedule__depart small,
  .flight-item-schedule__arrival small {
    font-size: 12px;
  }
  .flight-item-schedule__trajectory-arrow {
    width: 26%;
    position: relative;
    display: inline-block;
    height: 2px;
    background: #999;
    margin-left: 6px;
    margin-right: 9px;
    margin-bottom: 10px;
  }
  .flight-item-schedule__trajectory-arrow:after {
    content: "";
    position: absolute;
    top: -4px;
    display: block;
    width: 0;
    height: 0;
    border: 10px solid #999;
    right: -4px;
    border: 5px solid transparent;
    border-right: 0;
    border-left: 8px solid #999;
    border-radius: 3px;
  }
  .flight-item-details {
    color: #777;
    font-size: 12px;
    line-height: 1.5;
  }
  .flight-item-details__flight-night,
  .flight-item-details__flight-time,
  .flight-item-details__flight-nextDay {
    display: inline-block;
  }
  .flight-item-details__flight-connection,
  .flight-item-details__flight-returns,
  .flight-item-details__flight-number {
    display: block;
  }
  .flight-item-details__flight-night:before {
    content: "";
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 512 512' enable-background='new 0 0 512 512' xml:space='preserve'%3e%3cpath fill='%23777' d='M3.92,354.4a15.74,15.74,0,0,1,17.52-4.52c29.07,11,58.44,16.56,87.31,16.56h0c76.89,0,147.55-39.79,189-106.4,42-67.46,45.82-147.46,10.44-219.51a15.71,15.71,0,0,1,13.62-22.62l4.91-.16a15.88,15.88,0,0,1,7.34,1.51C435.27,67.62,492.2,162.14,486.42,272.12,479.35,406.63,363.72,512,223.19,512q-9.28,0-18.62-.63c-86.75-5.95-151-50.1-202.29-138.95A15.68,15.68,0,0,1,3.92,354.4Z M167.69,202.82l18.34,56a5.14,5.14,0,0,1-2.07,5.87,6.06,6.06,0,0,1-3.39,1,5.87,5.87,0,0,1-3.22-.94l-52-33.49-52,33.49a6.05,6.05,0,0,1-6.61-.09,5.12,5.12,0,0,1-2.07-5.87l18.33-56L32.46,167.45a5.13,5.13,0,0,1-2-5.91,5.7,5.7,0,0,1,5.32-3.71l63.35-1.18,20.78-55.33a5.87,5.87,0,0,1,10.79,0l20.81,55.33,63.34,1.18a5.71,5.71,0,0,1,5.31,3.71,5.16,5.16,0,0,1-2,5.91Z M78.49,42.36,85.4,65a2.18,2.18,0,0,1-.78,2.37,2.09,2.09,0,0,1-1.27.41,2.19,2.19,0,0,1-1.23-.37L62.51,53.86,42.9,67.38A2.16,2.16,0,0,1,39.61,65l6.93-22.61-19-14.25a2.15,2.15,0,0,1-.74-2.38,2.2,2.2,0,0,1,2-1.51l23.88-.47,7.84-22.3a2.14,2.14,0,0,1,4.05,0l7.85,22.3,23.87.47a2.19,2.19,0,0,1,2,1.51,2.12,2.12,0,0,1-.77,2.38Z'/%3e%3c/svg%3e");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: text-top;
  }
  .flight-item-details__flight-nextDay {
    font-weight: 600;
    color: #990000;
    margin-left: 12px;
  }
  .flight-item-families__item-form-label {
    display: block;
    text-align: center;
    min-height: 5rem;
    font-size: 12px;
  }
  .flight-item-families__item-form-label input:checked ~ span,
  .flight-item-families__item-form-label input:checked ~ small {
    font-weight: 600;
  }
  .flight-item-families__item-unavailable-label {
    display: block;
    text-align: center;
    margin: 0 0.5rem;
    padding: 0.75rem 0;
    font-size: 13px;
    color: #999;
  }
  .flight-item-families__item-radiobutton {
    margin: 0.25rem auto 0.75rem;
    display: block;
  }
  /* PopUp de escalas */
  .flight-group .bubble {
    position: absolute;
    z-index: 10000;
    top: 126px;
    width: auto;
  }
  .flight-group .bubble-content {
    min-width: 540px;
    min-height: auto;
    background-color: #fff;
    padding: 1em;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
  .flight-group .bubble-content .leg {
    display: block;
    padding: 0;
    border: none;
  }
  .flight-group .leg .leg-title {
    background-color: #f6f6f6;
    padding: 0.5em;
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .flight-group .leg .leg-type {
    display: inline-block;
  }
  .flight-group .leg-type .depart,
  .flight-group .leg-type .return {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 0.75em 0 0.5em;
    vertical-align: middle;
  }
  .flight-group .leg-type .depart {
    background-position: -141px -427px;
  }
  .flight-group .leg-type .return {
    background-position: -157px -427px;
  }
  .flight-group .leg-title .airport-code {
    font-weight: 600;
    cursor: default;
  }
  .flight-group .leg-title .leg-date {
    float: right;
    color: #777;
  }
  .flight-group .leg .halflings-icon {
    opacity: 0.4;
  }
  .flight-group .leg .schedule {
    background-color: #fff;
    padding: 0.75em;
  }
  .flight-group .schedule label {
    display: block;
  }
  .flight-group .schedule span {
    display: inline-block;
    vertical-align: middle;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-box;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-box-align: center;
  }
  .flight-group .schedule .airline-logo {
    width: 25%;
    margin-right: 0.5em;
  }
  .flight-group .schedule .airline-logo .icon {
    display: inline-block;
    padding-right: 0.5em;
  }
  .flight-group .schedule .airline-logo .airline-name {
    display: inline-block;
    max-width: 103px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-box;
    -webkit-box-flex: 1;
  }
  .flight-group .schedule .flight-number {
    width: 16%;
    color: #777;
  }
  .flight-group .schedule .flight-time {
    font-weight: 600;
  }
  .flight-group .schedule .departure-time,
  .flight-group .schedule .arrival-time,
  .flight-group .schedule .duration {
    width: 10%;
  }
  .flight-group .schedule .separator-img {
    padding: 0 0.5em;
  }
  .flight-group .leg .warning-message {
    background-color: #f9efc8;
    padding: 0.5em;
    margin: 0;
  }
  .flight-group .leg .schedule:hover {
    background-color: #f9f9f9;
  }
  /* Tooltip - Tu tarifa incluye */
  .flight-grid-header .ptTooltip {
    position: relative;
  }
  .flight-grid-header .ptTooltip .icon-info {
    color: #1b4298;
    font-size: 12px;
    cursor: pointer;
  }
  .flight-grid-header .ptTooltip .ptTooltip-item {
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
  .flight-grid-header .ptTooltip .ptTooltip-content::after {
    display: none;
  }
  .flight-grid-header .ptTooltip .ptTooltip-content {
    position: absolute;
    text-align: left;
    left: 0;
    top: 35px;
    margin: -10px 10px 0 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    padding-bottom: 10px;
    width: 280px;
    border-radius: 5px;
    background: #fcfcfc;
    opacity: 0;
    cursor: default;
    transition:
      opacity 0.3s,
      transform 0.3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    pointer-events: none;
    z-index: 999 !important;
  }
  .flight-grid-header .ptTooltip:hover .ptTooltip-content {
    opacity: 1;
    pointer-events: auto;
    z-index: 999 !important;
    display: block;
  }
  .flight-grid-header .ptTooltip .ptTooltip-content::before {
    position: absolute;
    content: "";
    right: calc(96% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #464646 transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
  .flight-grid-header .ptTooltipContent .ptTooltipContent-title {
    color: #fff;
    background-color: #464646;
    padding: 7px 8px 5px;
    margin: 0;
    border-radius: 5px 5px 0 0;
  }
  .flight-grid-header .ptTooltipContent li {
    padding: 3px 0;
    clear: both;
  }
  .flight-grid-header .ptTooltipContent span {
    float: left;
    display: inline-block;
    max-width: 240px;
    line-height: 16px;
  }
  .flight-grid-header .ptTooltipContent .icon-ijsmcheck {
    background-position: -5px -445px;
    height: 20px;
    width: 30px;
  }
}

/* TARIFAS AVIANCA */

/* TARIFA AVIANCA - AV SUPER PROMO */
.ptTooltip-item.ptTooltip-tarifa-AV-superpromo {
  color: #ff6766;
}
.ptTooltipContent-tarifa-AV-superpromo .ptTooltipContent-title {
  background: #ff6766 !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-AV-superpromo:before {
  border-color: transparent transparent #ff6766 transparent !important;
}

/* TARIFA AVIANCA - AV ECONO */
.ptTooltip-item.ptTooltip-tarifa-AV-econo {
  color: #e0c14e;
}
.ptTooltipContent-tarifa-AV-econo .ptTooltipContent-title {
  background: #e0c14e !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-AV-econo:before {
  border-color: transparent transparent #e0c14e transparent !important;
}

/* TARIFA AVIANCA - AV FLEXI */
.ptTooltip-item.ptTooltip-tarifa-AV-flexi {
  color: #e6a47a;
}
.ptTooltipContent-tarifa-AV-flexi .ptTooltipContent-title {
  background: #e6a47a !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-AV-flexi:before {
  border-color: transparent transparent #e6a47a transparent !important;
}

/* TARIFA AVIANCA - AV EJECUTIVA PROMO */
.ptTooltip-item.ptTooltip-tarifa-AV-ejecutivap {
  color: #6794b1;
}
.ptTooltipContent-tarifa-AV-ejecutivap .ptTooltipContent-title {
  background: #6794b1 !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-AV-ejecutivap:before {
  border-color: transparent transparent #6794b1 transparent !important;
}

/* TARIFA AVIANCA - AV EJECUTIVA */
.ptTooltip-item.ptTooltip-tarifa-AV-ejecutiva {
  color: #9aa1be;
}
.ptTooltipContent-tarifa-AV-ejecutiva .ptTooltipContent-title {
  background: #9aa1be !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-AV-ejecutiva:before {
  border-color: transparent transparent #9aa1be transparent !important;
}

/* TARIFAS LATAM */

/* TARIFA LATAM - LA PROMO */
.ptTooltip-item.ptTooltip-tarifa-LA-promo {
  color: #f7c300;
}
.ptTooltipContent-tarifa-LA-promo .ptTooltipContent-title {
  background: #f7c300 !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-promo:before {
  border-color: transparent transparent #f7c300 transparent !important;
}

/* TARIFA LATAM - LA LIGHT */
.ptTooltip-item.ptTooltip-tarifa-LA-light {
  color: #8ccd53;
}
.ptTooltipContent-tarifa-LA-light .ptTooltipContent-title {
  background: #8ccd53 !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-light:before {
  border-color: transparent transparent #8ccd53 transparent !important;
}

/* TARIFA LATAM - LA PLUS */
.ptTooltip-item.ptTooltip-tarifa-LA-plus {
  color: #00b3ab;
}
.ptTooltipContent-tarifa-LA-plus .ptTooltipContent-title {
  background: #00b3ab !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-plus:before {
  border-color: transparent transparent #00b3ab transparent !important;
}

/* TARIFA LATAM - LA PLUS ECONOMY */
.ptTooltip-item.ptTooltip-tarifa-LA-plus-economy {
  color: #00b3ab;
}
.ptTooltipContent-tarifa-LA-plus-economy .ptTooltipContent-title {
  background: #00b3ab !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-plus-economy:before {
  border-color: transparent transparent #00b3ab transparent !important;
}

/* TARIFA LATAM - LA PLUS BUSINESS */
.ptTooltip-item.ptTooltip-tarifa-LA-plus-business {
  color: #00b3ab;
}
.ptTooltipContent-tarifa-LA-plus-business .ptTooltipContent-title {
  background: #00b3ab !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-plus-business:before {
  border-color: transparent transparent #00b3ab transparent !important;
}

/* TARIFA LATAM - LA TOP */
.ptTooltip-item.ptTooltip-tarifa-LA-top {
  color: #da30b3;
}
.ptTooltipContent-tarifa-LA-top .ptTooltipContent-title {
  background: #da30b3 !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-top:before {
  border-color: transparent transparent #da30b3 transparent !important;
}

/* TARIFA LATAM - LA TOP ECONOMY */
.ptTooltip-item.ptTooltip-tarifa-LA-top-economy {
  color: #da30b3;
}
.ptTooltipContent-tarifa-LA-top-economy .ptTooltipContent-title {
  background: #da30b3 !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-top-economy:before {
  border-color: transparent transparent #da30b3 transparent !important;
}

/* TARIFA LATAM - LA TOP BUSINESS */
.ptTooltip-item.ptTooltip-tarifa-LA-top-business {
  color: #da30b3;
}
.ptTooltipContent-tarifa-LA-top-business .ptTooltipContent-title {
  background: #da30b3 !important;
}
.ptTooltip-content.ptTooltipContent-tarifa-LA-top-business:before {
  border-color: transparent transparent #da30b3 transparent !important;
}

/* css agregado directo a dashboard */

.terminos-apartado {
  line-height: 1.5;
  max-width: 1100px;
  margin: auto;
  text-align: justify;
}

.terminos-apartado li {
  margin: 1em 0;
  font-size: 14px;
}

/* fixes a general afiliados viejitos */
.botAzu,
.botAzu.mSprite,
.btn,
.bttn,
.butCom {
  text-align: center;
}

.paginas a:visited,
.paginas a:link,
.paginas a,
.pager .currPage,
.pager .currentPage,
.paginas a.currPage {
  min-width: 24px;
  display: inline-block;
  padding: 5px 2px !important;
}

.pager .currPage,
.pager .currentPage,
.paginas a.currPage {
  padding: 5px 2px 7px 2px !important;
}

#cajaErr li {
  list-style: none;
}

#cajaErr,
#divError,
#divErrorFlight,
.cajaErr,
.divErr {
  padding: 10px;
}

#main p {
  line-height: 150%;
}

.rateHalf.mSprite,
.rateOne.mSprite,
.rateOnehalf.mSprite,
.rateTwo.mSprite,
.rateTwohalf.mSprite,
.rateThree.mSprite,
.rateThreehalf.mSprite,
.rateFour.mSprite,
.rateFourhalf.mSprite,
.rateFive.mSprite {
  background: url(https://cdnpt.com/images/spritesmaster32.png?v=1) no-repeat;
}

.rateHalf.mSprite,
.rateOne.mSprite,
.rateOnehalf.mSprite,
.rateTwo.mSprite,
.rateTwohalf.mSprite,
.rateThree.mSprite,
.rateThreehalf.mSprite,
.rateFour.mSprite,
.rateFourhalf.mSprite,
.rateFive.mSprite {
  width: 100px;
  height: 11px;
  display: inline-block;
  text-indent: -10000px;
  vertical-align: middle;
}

.rateHalf.mSprite {
  background-position: 0 -1619px;
}

.rateOne.mSprite {
  background-position: -100px -1619px;
}

.rateOnehalf.mSprite {
  background-position: 0 -1630px;
}

.rateTwo.mSprite {
  background-position: -100px -1630px;
}

.rateTwohalf.mSprite {
  background-position: 0 -1641px;
}

.rateThree.mSprite {
  background-position: -100px -1641px;
}

.rateThreehalf.mSprite {
  background-position: 0 -1652px;
}

.rateFour.mSprite {
  background-position: -100px -1652px;
}

.rateFourhalf.mSprite {
  background-position: 0 -1663px;
}

.rateFive.mSprite {
  background-position: -100px -1663px;
}

/* css generales de cambios de responsivo para tabla de ocultar y mostrar */

.comparison-table li.hotelpicture img {
  width: 70px;
  height: 70px;
}

.comparison-table li.reserveBtn {
  padding: 0;
  height: auto;
}

.collapsedLabel {
  display: none;
}

.visibleLabel {
  display: inline-block;
}

.collapsed .collapsedLabel {
  display: inline-block;
}

.collapsed .visibleLabel {
  display: none;
}

/* ocultar label que dispara menu para agencias no responsive  */
.agencyBarMenu {
  margin: 0;
}
#agencyBarMenu-check {
  display: none;
}
.agencyBarMenu-label {
  display: none;
}

/*Estilos para o descolapsar descripción familias tarifarias */
.disable-collapsed .ptTooltip-content {
  display: none;
}

/*Oculta información de familia tarifaria en listado de cambio de vuelo*/
#flgtlist.old-flightsList .schedule .ptTooltip-content {
  display: none !important;
}
.old-flightsList .icon-info {
  display: none;
}

/*Terminos y condiciones review*/
#divHotelAdvisory {
  color: #000;
  font-size: 14px;
}
#divHotelAdvisory ul ul li {
  list-style: disc;
  margin-left: 20px;
  font-weight: 400;
}
#divHotelAdvisory .nobul {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

/*Cajas de busquedas resposivas*/
#Content {
  width: 100%;
  max-width: 1200px;
}
#Content .home {
  width: 70%;
}

#hsidebar {
  width: 20%;
  min-width: 215px;
}
@media (max-width: 514px) {
  #Content .home {
    width: 100%;
    margin: 0 !important;
  }
  #hsidebar {
    width: 100%;
    min-width: 215px;
    display: none;
  }
  #hsidebar #contact {
    text-align: center;
    margin: 0 0 20px 0;
  }
  #hsidebar #contact h2 {
    text-align: center;
  }
}

/*Autos etiqueta Reservación flexible*/
p.category span.flexible-package {
  background: #e7f5d5;
  color: #214600;
}

/* INICIA TOURS*/
.content-tour h1 {
  color: #323232;
  line-height: 130%;
  font-size: 30px;
}
.content-tour h3 {
  font-size: 20px;
  line-height: 130%;
  color: #6d7278;
}
.content-tour p,
.content-tour li {
  font-size: 14px;
  color: #6a6a6a;
  margin: 0.5em 0 1.2em 0;
}
.content-tour h4 {
  font-size: 18px;
  margin: 0.6em;
}
.tour-heading-price {
  display: inline-block;
  width: 100%;
  float: left;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.tour-heading-price p {
  margin: 0;
  padding: 0;
}
p.tour-heading-price-current {
  font-weight: bold;
}
p.tour-heading-price-offer {
  font-weight: 100;
  text-decoration: line-through;
}
p.tour-heading-price-total {
  font-size: 18px;
  color: #323232;
}
p.tour-heading-price-notes {
  font-size: 12px;
}
ul li.promo-tour {
  padding: 0 20px;
  box-sizing: border-box;
}
li.promo-tour {
  padding: 5px 0;
  color: #464646;
  font-weight: 400;
}
li.promo-tour p:before {
  content: "\2022";
  color: $gray-400;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.info-tour {
  float: left;
  width: 100%;
  border-top: 1px solid #f1f1f1;
  padding: 20px 0;
}
.button-blue {
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  background: #0077dd;
  color: #fff;
  line-height: 1.5;
  font-size: 14px;
  border-radius: 0.25rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  border: none;
  transition: 0.2s ease-in-out;
  padding: 0.75rem;
  margin: 1rem 0;
  width: 95%;
}

.gallery-principal {
  height: 365px;
}

.gallery-grid-item img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
}
.gallery-grid-item {
  overflow: hidden;
  position: relative;
  width: 98%;
  height: 98%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.gallery-grid-item-action {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color: #fff;
  height: 24px;
}
.gallery-principal .gallery-grid-item-action {
  top: auto;
  left: auto;
  right: 1rem;
}
.tarifas-promotions h4 {
  color: #73a536 !important;
  font-size: 18px;
}
.card-day-sticker {
  position: relative;
  padding: 20px 5px 10px 5px !important;
}
.offer-flag {
  position: absolute;
  top: -1px;
  right: 0;
}
.alert-tour {
  display: table;
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #b9b7b8;
  border-left-width: 0.5rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.alert--warning {
  border-left: 0.5rem solid #fcb000 !important;
  background-color: #fff !important;
}
.alert__content-title {
  color: #333;
}
.alert--warning {
  padding: 2px 15px !important;
  box-sizing: border-box;
}
.main-tour {
  width: 95%;
  max-width: 820px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-box-shadow: 0 1px 2px #aaa;
  -webkit-box-shadow: 0 1px 2px #aaa;
  box-shadow: 0 1px 2px #aaa;
  background: #fff;
  margin: 0 0 5em 0;
  padding: 10px;
  float: left;
}
.content-tour {
  float: left;
  position: relative;
  width: 100%;
}
.content-tour .loader img {
  position: absolute;
  top: 4rem;
  left: 42%;
  z-index: 99;
  background-color: rgb(255, 255, 255);
  opacity: 0.5;
  display: block;
  width: auto;
}
.gallery-grid .gallery-show-modal img {
  filter: brightness(0.4);
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tour-heading-price {
    width: 24%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .button-blue {
    width: auto;
  }
  .gallery-principal,
  .gallery-grid {
    position: relative;
    width: 100%;
    min-height: 1px;
  }
  .gallery-principal {
    width: 50%;
    height: 365px;
    display: inline-block;
  }
  .gallery-principal .gallery-grid-item-mask,
  .gallery-principal .gallery-grid-item-action {
    display: none;
  }
  .gallery-grid {
    right: 0;
    width: 50%;
    top: 0;
    position: relative;
    float: right;
    height: 237px;
  }
  .gallery-grid-col {
    margin-bottom: 24px;
    height: 183px;
    width: 50%;
    margin: 0;
    float: left;
  }
  .gallery-grid .gallery-grid-item {
    width: 96%;
    height: 94%;
  }
  .no-desktop-tour {
    display: none !important;
  }
}

/*FIN DE TOUR*/

/*Deetalles del tour*/

.detailInfoTour {
  overflow: hidden;
}
.detailInfoTour-left {
  width: 40%;
  float: left;
}
.detailInfoTour-right {
  width: 60%;
  float: right;
}
.detailInfoTour p {
  margin: 0 0 5px 0;
}
.detailInfoTour span {
  font-weight: bold;
}

@media (max-width: 415px) {
  .detailInfoTour-left {
    width: 100%;
    float: left;
  }
  .detailInfoTour-right {
    width: 100%;
    float: left;
  }
}

/*Ajustes agencias*/

/*Menu admin*/
ul.agencyBarMenu {
  margin: 0;
  padding: 0;
}
li.agencyOption {
  list-style: none;
}
#agencyBarMenu-check {
  display: none;
}
.agencyBarMenu-label {
  display: none;
}
.home.landing-home {
  margin-top: 34px;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  text-indent: -99999px;
  vertical-align: top;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000000;
  opacity: 0.3;
  filter: alpha(opacity=30);
  content: "\2193";
}
#agencyBar,
#agencyBar a {
  color: #fff;
}
@media (max-width: 992px) {
  #agencyBar .agencyBar-inner {
    width: 100%;
  }
  #agencyBar .agencyName#headerAgencyGame {
    margin-left: 10px;
  }
  div#agencyBar {
    position: relative;
    height: 90px;
  }
  div#agencyBar:after {
    content: " ";
    clear: both;
    display: block;
    height: 40px;
  }
  .agencyBarMenu-label {
    position: absolute;
    display: block;
    font-weight: bold;
    padding: 12px 10px 0 10px;
    height: 28px;
    top: 50px;
  }
  .agencyBarMenu-label {
    right: 0;
  }
  .agencyBarMenu {
    display: none;
  }
  .agencyBarMenu-label .caret {
    margin-top: 8px;
    border-top: solid 4px;
  }
  input:checked + label + .agencyBarMenu,
  input:checked + label + ul#menu {
    display: block;
  }
  input:checked + label .caret,
  input:checked + label .caret {
    border-top: none;
    border-bottom: solid 4px;
    margin-top: 7px;
  }
  .agencyBarMenu {
    position: absolute;
    top: 90px;
  }
  .agencyBarMenu {
    width: 230px;
  }
  .agencyBarMenu {
    -webkit-box-shadow:
      -1px 1px 1px rgba(0, 0, 0, 0.15),
      inset 0 -1px 0 rgba(0, 0, 0, 0.05);
    box-shadow:
      -1px 1px 1px rgba(0, 0, 0, 0.15),
      inset 0 -1px 0 rgba(0, 0, 0, 0.05);
  }
  .agencyBarMenu {
    right: 0;
  }
  div#agencyBar .agencyBarMenu li {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  div#agencyBar .agencyBarMenu li a {
    border: none;
    text-shadow: none;
  }
  div#agencyBar .agencyBarMenu li a:hover {
    background: none;
  }
  div#agencyBar .agencyBarMenu li a {
    font-weight: bold;
    text-align: right;
    padding: 2px 7px;
  }
  .agencyBarMenu .dropdown > a {
    pointer-events: none;
  }
  div#agencyBar .agencyBarMenu .dropdown ul.dropdown-menu {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    border-left: none;
    border-right: none;
    border-radius: 0;
    -webkit-box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.2);
    box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    max-height: none;
  }
  #agencyBar .agencyBarMenu .dropdown a {
    padding: 6px 10px;
    text-align: left;
  }
  #agencyBar .agencyBarMenu .dropdown a .caret {
    display: none;
  }
  #agencyBar .agencyBarMenu li.dropdown > a {
    color: #333;
  }
  .ageLog div#container2 {
    padding-top: 0;
  }
  .comissionsDetail,
  .comissionsDetailsCommission {
    width: 100%;
    margin: 15px 0;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 415px) {
  .agentName .agentName-label {
    display: none;
  }
  div#agencyBar .inactiveAgent {
    font-size: 0;
  }
  div#agencyBar .inactiveAgent .btnLogout {
    font-size: 12px;
  }
  div#agencyBar span.agencyName {
    width: 160px;
    margin: 0 5px;
  }
}

/*RAPD detalle tarifa*/
#PagaDes.tdPaDe {
  font-size: 13px;
  padding: 5px;
  background: #f9f9f9;
  background: -moz-linear-gradient(top, #f9f9f9 29%, #e8fbff 100%);
  background: -webkit-linear-gradient(top, #f9f9f9 29%, #e8fbff 100%);
  background: linear-gradient(to bottom, #f9f9f9 29%, #e8fbff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e8fbff', GradientType=0);
  border: 1px solid #e2e0e0;
  margin-bottom: 4px;
  border-radius: 4px;
  box-shadow: 0 1px #a7a7a7;
  text-align: center;
}
i.icon-time {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:a='http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/' x='0px' y='0px' width='13.1px' height='15.3px' viewBox='0 0 13.1 15.3' style='enable-background:new 0 0 13.1 15.3%3B' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:%23F00953%3B%7D %3C/style%3E %3Cdefs%3E %3C/defs%3E %3Cg%3E %3Cpath class='st0' d='M11.8%2C4.6L12.2%2C5l0.9-0.8l-1.4-1.6l-0.9%2C0.8L11%2C3.7l-0.9%2C0.7C9.2%2C3.6%2C8.1%2C3.1%2C6.8%2C3V1.8h1.4V0H4.2v1.8h1.4V3 C2.5%2C3.3%2C0%2C5.9%2C0%2C9.1c0%2C3.4%2C2.8%2C6.2%2C6.2%2C6.2s6.2-2.8%2C6.2-6.2c0-1.5-0.5-2.8-1.4-3.8L11.8%2C4.6z M6.2%2C14.1c-2.7%2C0-5-2.2-5-5 s2.2-5%2C5-5c2.7%2C0%2C5%2C2.2%2C5%2C5S8.9%2C14.1%2C6.2%2C14.1z'/%3E %3Crect x='5.6' y='7.1' transform='matrix(0.7134 -0.7007 0.7007 0.7134 -3.2574 7.5201)' class='st0' width='3.9' height='1.2'/%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 20px;
  vertical-align: text-bottom;
  padding: 1px;
  margin-right: 5px;
}

/*Paquetes flexibles*/
.accordion-module .option-inline span:first-child {
  display: inline-block;
  min-width: 70px;
  text-align: right;
}
.ticket li.ticket-cruiceLeg,
.ticket li.ticket-flightLeg,
.ticket li.ticket-insuranceItem,
.ticket li[class^="ticket-transferLeg"],
.ticket-subItemSeparator {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  margin-left: 7%;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.ticket li.ticket-cruiceLeg:last-of-type,
.ticket li.ticket-flightLeg:last-of-type,
.ticket li.ticket-insuranceItem:last-of-type,
.ticket li[class^="ticket-transferLeg"]:last-of-type,
.ticket-subItemSeparator:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.ticket-itemDiscount {
  background-color: #f6fdf6;
}
.stepsBar .stepsBar-item .stepsBar-link--pending,
.ticket-item--pending,
.ticket-itemPending-heading {
  background-color: #f9f9f9;
}
.ticket-item--ready .ticket-itemHeading,
.ticket-itemPax .ticket-itemHeading,
.ticket-itemReady-heading {
  background-color: rgba(0, 57, 90, 0.06);
  color: #004982;
}
.ticket-itemContent,
.ticket-itemReady-contentBG {
  background: #e0e0e0;
  background: -moz-linear-gradient(top, #e0e0e0 0, #f7f7f7 3%, #fcfcfc 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e0e0e0),
    color-stop(3%, #f7f7f7),
    color-stop(100%, #fcfcfc)
  );
  background: -webkit-linear-gradient(top, #e0e0e0 0, #f7f7f7 3%, #fcfcfc 100%);
  background: -o-linear-gradient(top, #e0e0e0 0, #f7f7f7 3%, #fcfcfc 100%);
  background: -ms-linear-gradient(top, #e0e0e0 0, #f7f7f7 3%, #fcfcfc 100%);
  background: linear-gradient(to bottom, #e0e0e0 0, #f7f7f7 3%, #fcfcfc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0e0e0', endColorstr='#fcfcfc', GradientType=0 );
}
.paqueteFlexible .hotel--selected.hotel-item,
.paqueteFlexible .room--selected.roomsContainer,
.stepsBar .stepsBar-item .stepsBar-link--current,
.ticket-currentItem .ticket-itemHeading,
.ticket-itemCurrent-heading {
  background-color: #fcf8e3;
  color: #5c594c;
}
.ticket-currentItem .ticket-itemContent,
.ticket-itemCurrent-contentBG {
  background: #e0dcce;
  background: -moz-linear-gradient(top, #e0dcce 0, #f7f5ea 3%, #fffef7 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e0dcce),
    color-stop(3%, #f7f5ea),
    color-stop(100%, #fffef7)
  );
  background: -webkit-linear-gradient(top, #e0dcce 0, #f7f5ea 3%, #fffef7 100%);
  background: -o-linear-gradient(top, #e0dcce 0, #f7f5ea 3%, #fffef7 100%);
  background: -ms-linear-gradient(top, #e0dcce 0, #f7f5ea 3%, #fffef7 100%);
  background: linear-gradient(to bottom, #e0dcce 0, #f7f5ea 3%, #fffef7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0dcce', endColorstr='#fffef7', GradientType=0 );
  border-top: 0;
}
.ticket li,
.ticket ol,
.ticket ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: 0 0;
  font-size: 12px;
}
.ticket a {
  text-decoration: none;
}
.ticket a:hover {
  text-decoration: underline;
}
.ticket .currency {
  text-align: left;
}
.clearfix:after,
.ticket-complete:after,
.ticket-itemContent:after,
.ticket-modify:after,
.ticket-totalRate:after,
div[class^="ticket-itemHeading"]:after {
  content: " ";
  display: table;
  clear: both;
}
.ticket {
  font-size: 12px;
  background: #fcfcfc;
  border: 1px solid #e6e6e6;
  margin-bottom: 15px;
}
.ticket-itemContent,
.ticket-modify,
.ticket-productRates,
.ticket-totalRate {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.ticket .icon,
.ticket .ptw-icon {
  font-size: 1.35em;
  vertical-align: text-bottom;
  margin-bottom: -3px;
  display: inline-block;
  width: 20px;
}
.ticket-itemDiscount .ticket-itemHeading .ptw-icon,
.ticket-totalRate .ticket-totalRateAmount .amount,
.ticket-totalRate .ticket-totalRateAmount .currency {
  font-size: 1.2em;
}
.ticket .icon {
  padding: 0;
  font-family: ptw-icons;
}
.ticket-title {
  background: #006;
  color: #fff;
  margin: 0;
  padding: 7px;
}
.ticket td.amount {
  text-align: right;
  padding-right: 5px;
}
.ticket-itemDiscount .ticket-itemHeading {
  cursor: default;
  color: green;
}
.ticket-itemDiscount .ticket-headingInfo {
  width: 85%;
}
.ticket-totalRate {
  background-color: #f2f2f2;
}
.ticket-totalRate .ticket-totalRateWrapper {
  padding: 10px 5px;
}
.ticket-totalRate .ticket-totalRateTable {
  width: 100%;
}
.ticket-totalRate .ticket-totalRateTable td.amount {
  text-align: right;
  padding-right: 5px;
}
.ticket-totalRate .ticket-totalRateTable td.currency {
  max-width: 18px;
}
.ticket-totalRate .ticket-totalRateAmount td {
  font-weight: 700;
}
.ticket-modify {
  text-align: center;
}
.ticket-modify .btn {
  display: block;
  margin: 5px;
}
.ticket-complete .greenButton {
  display: block;
  text-align: center;
  margin: 5px;
  padding: 5px;
}
.ticket-headingInfo,
div[class^="ticket-itemHeading"] > * {
  display: inline-block;
}
div[class^="ticket-itemHeading"] {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 3px;
  cursor: pointer;
}
.paqueteFlexible .flight--selected.ap_group_container .schedule label,
.stepsBar .stepsBar-item .stepsBar-link--current,
.ticket-currentItem.ticket-item--pending .ticket-itemHeading,
.ticket-itemPax .ticket-itemHeading {
  cursor: default;
}
.ticket a .ptw-icon {
  margin-left: 5px;
  text-decoration: none;
}
.ticket-price {
  float: right;
  white-space: nowrap;
}
.ticket-collapseControl {
  float: right;
  line-height: 2em;
}
.ticket-headingInfo {
  width: 80%;
}
.ticket-pendingItemInfo {
  vertical-align: middle;
  max-width: 87%;
}
.ticket .ticket-itemPax .ptw-icon,
.ticket-paxInfo {
  vertical-align: top;
}
.ticket-itemContent {
  padding: 5px;
  display: none;
  color: #464646;
}
.ticket-productRates {
  margin-top: 7px;
  float: right;
  text-align: right;
  min-width: 95%;
}
.ticket-productRates tr:last-of-type .amount,
.ticket-productRates tr:last-of-type .currency {
  font-weight: 700;
}
.ticket-productRates td:first-of-type {
  padding-right: 10px;
}
.ticket-regularProductRates .amount {
  text-decoration: line-through;
}
.page-nav li,
.stepsBar .stepsBar-item .stepsBar-link:hover,
tr:last-of-type.ticket-regularProductRates .amount {
  text-decoration: none;
}
.ticket-paxInfo {
  width: 80%;
  line-height: 1.4em;
}
.ticket-paxInfo .ticket-paxMinor,
.ticket-paxInfo .ticket-paxRooms {
  white-space: nowrap;
}
.ticket-pendingItemInfo .ticket-pendingHotelDates {
  white-space: nowrap;
  margin-top: 3px;
  display: block;
  opacity: 0.9;
}
.ticket-pendingItemInfo .ticket-pendingProductName {
  font-weight: 700;
}
.ticket-pendingItemInfo .ticket-pendingFlights {
  margin-top: 3px;
}
.ticket li.ticket-cruiceLeg .ticket-cruiceLegInfo,
.ticket li.ticket-flightLeg .ticket-flightLegInfo,
.ticket-pendingItemInfo .ticket-pendingTransfer {
  margin-top: 5px;
}
.ticket-pendingItemInfo .ticket-iata {
  font-weight: 700;
}
.ticket-pendingItemInfo .ticket-pendingTransferLeg {
  margin-bottom: 2px;
}
.ticket-pendingItemInfo .ticket-transferDestination:after {
  content: "";
  display: table;
}
.ticket-flightlist,
.ticket-hotelDestination,
.ticket-hotelName,
.ticket-transferList {
  font-weight: 700;
  line-height: 1.5em;
}
.ticket-hotelName .ptw-icon {
  color: #e1be60;
  font-size: 1.2em;
  width: auto;
  vertical-align: top;
}
.ticket-hotelDestination {
  white-space: nowrap;
  max-width: 90%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
}
.ticket-hotelRoom,
.ticket-hotelServiceDates {
  margin-left: 7%;
}
.ticket-hotelServiceDates {
  opacity: 0.6;
}
.ticket li.ticket-flightLeg .ticket-iata {
  font-weight: 700;
}
.ticket li.ticket-flightLeg .ticket-airLogo {
  display: inline-block;
  width: 20%;
  margin-right: 2%;
  margin-bottom: 5px;
  vertical-align: top;
  text-align: center;
}
.ticket li.ticket-flightLeg .ticket-flightLeg-serviceHours {
  width: 75%;
  display: inline-block;
  opacity: 0.6;
}

/*Vuelos*/
.btn-more-group-flights {
  background-color: white;
  color: #008cba;
  border: 2px solid #008cba;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 30%;
  margin-right: 30%;
}

.resumen-flight {
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 37%;
  margin-right: 30%;
}

/*Collapsado de vuelos*/
.flight-bound-options {
  position: relative;
  height: auto;
  transition: 0.2s ease-in-out;
}

.flightFaresCollapsed {
  height: 400px;
  overflow-y: hidden;
  transition: 0.2s ease-in-out;
}
.flightFaresCollapsedButton {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 40px 0px 10px 0px;
  text-align: center;
  font-weight: bold;
  color: #06c;
  background: rgb(255, 255, 255);
  background: linear-gradient(360deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  cursor: pointer;
  /*! margin-top: 15px; */
}
.flightFaresCollapsed .flightFaresCollapsedButton {
  position: absolute;
}
.flight-bound-options .flightFaresCollapsedButton .flightFaresCollapsedButton__icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: 0.2s ease-in-out;
}
.flightFaresCollapsed .flightFaresCollapsedButton .flightFaresCollapsedButton__icon {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}
.flightFaresCollapsedButton__label,
.flightFaresCollapsedButton__icon {
  display: block;
}

.flgtlistFooter {
  padding: 16px 0;
}
.flgtlistFooter__button {
  display: block;
  width: 100%;
  text-align: center;
}

/*Spinner vuelos*/
.splash-spinner {
  background: url("https://cdnpt.com/affiliates/general/images/Widget-v2-loader.gif") no-repeat;
  display: block;
  width: 16px;
  height: 16px;
}

.list-item {
  border-top: 1px #ddd dashed;
  padding: 0 0 0 10px;
  margin-top: 5px;
}

.detailAlert {
  padding-left: 10px;
}
/*INICIO boton outline*/
.btn-outline-blue:link,
.btn-outline-blue:visited {
  color: #0165cc;
  border: 1px solid #0165cc;
  padding: 3px 5px;
  border-radius: 5px;
  text-decoration: none;
}
.btn-outline-blue:hover {
  color: #fff;
  background: #0165cc;
  transition: 0.25s linear;
}
/*FINboton outline*/

/* Link de pago - Pendientes de pago */
.datTab .payment-link {
  text-align: center;
}
.datTab .payment-link .bton-copy-link {
  font-size: 12px;
}
.datTab .payment-link .confirmation {
  color: #008000;
  background: #dfefdf;
  padding: 2px 4px;
  border-color: #c7e3c7;
  margin: 0;
}

/*Fix para galeria de Tour*/
#tourGalleryContainer .gallery-principal {
  height: 270px;
}
#tourGalleryContainer .gallery-grid-col {
  height: 137px;
}

/*Galería Carrusel*/
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s linear;
}

.carousel-item img.hotImg {
  margin: 0;
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /*width: 15%;*/
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  /*opacity: 0.5;*/
  transition: opacity 0.6s linear;
  background: none;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
  background: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgba(11, 35, 67, 0.7);
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  background-image: none;
}

.carousel-control-prev-icon {
  margin: 0px 0px 0px 8px;
  transform: rotate(-180deg);
}

.carousel-control-prev-icon::after,
.carousel-control-next-icon::after {
  position: absolute;
  content: "";
  background-image: url("data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iMTAwcHgiIGhlaWdodD0iMTAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTE2LjYxNzMgMTYuOTIzOUMxNi4yNDM2IDE2Ljc2OTEgMTYgMTYuNDA0NSAxNiAxNlYxMy41SDNDMi40NDc3MiAxMy41IDIgMTMuMDUyMyAyIDEyLjVWMTEuNUMyIDEwLjk0NzcgMi40NDc3MiAxMC41IDMgMTAuNUgxNlY4QzE2IDcuNTk1NTQgMTYuMjQzNiA3LjIzMDkgMTYuNjE3MyA3LjA3NjEyQzE2Ljk5MSA2LjkyMTM0IDE3LjQyMTEgNy4wMDY4OSAxNy43MDcxIDcuMjkyODlMMjEuNzA3MSAxMS4yOTI5QzIyLjA5NzYgMTEuNjgzNCAyMi4wOTc2IDEyLjMxNjYgMjEuNzA3MSAxMi43MDcxTDE3LjcwNzEgMTYuNzA3MUMxNy40MjExIDE2Ljk5MzEgMTYuOTkxIDE3LjA3ODcgMTYuNjE3MyAxNi45MjM5WiIgZmlsbD0iI2ZmZmZmZiIvPiA8L2c+Cg08L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
}

.carousel .item {
  transition: 0.6s linear left !important;
}

.sr-only,
.sr-only-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.itemTagRibbon-discount {
  background-color: #157347;
  line-height: 1.1;
  z-index: 1;
}

/*Botón subir*/
#toTop {
  display: none;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 20px;
  outline: none;
  background: url("../images/back.png") no-repeat 50%;
  background-size: auto;
  background-size: 50px auto;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  margin-bottom: 20px;
}
div#toTop::before {
  content: " ";
  width: 50px;
  height: 50px;
  display: block;
  text-indent: -9999px;
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 20px;
  outline: none;
  background: url("https://b2b-b2b2c.s3.amazonaws.com/recursos/images/topback.png") no-repeat 50%;
  background-size: auto;
  background-size: 50px auto;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  margin-bottom: 20px;
}

/*Info vuelos*/
.type-flight {
  border-left: 1px #ddd solid;
  padding-left: 5px;
  font-size: 12px;
}

.number-flight {
  font-size: 12px;
}

.number-terminal {
  font-weight: normal;
  margin: 0 2px;
  font-size: 12px;
}

.view-more-tarifas {
  margin-top: 10px;
}

.number-terminalT {
  margin-left: 8%;
}

.box-info-flight {
  text-align: center;
  background: #f0f8fb;
  margin-top: 5px;
  padding: 2px;
}
.app_quitFilter img {
  display: none;
}
.fa-search::before,
#filter #searchButton::before {
  content: $icons-search;
  font-size: 18px;
  vertical-align: middle;
  font-family: "PTH-icon";
}
.btn-success .caret,
#filter #searchButton::after {
  content: $icons-search;
  font-family: "PTH-icon";
  color: #b4b1b2;
  opacity: 1;
  border-top-color: unset;
}

@media (min-width: 1200px) {
  #wrapper .sidebar-module {
    display: block !important;
  }
  #wrapper .sidebar-summary {
    display: none !important;
  }
}

@media (min-width: 600px) {
  #wrapper .sidebar-module {
    display: none;
  }
  #wrapper .sidebar-summary {
    display: block;
  }
}
// Mensaje VirtualInterline
.cajaErr.cajaErr--vI {
  width: fit-content;
  padding: 8px 12px;
  border: 1px solid var(--border-border-semantic-border-warning, #f2bd0d);
  background: var(--bg-semantic-bg-success-subtle, #fffef2);
  color: var(--text-semantic-text-warning-strong, #664f05);
  margin: 4px;
  p {
    margin: 0px;
  }
  .font-icons {
    color: var(--icon-semantic-icon-warning, #f2bd0d);
  }
}

/*skeleton*/
.skeleton__element {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center
}

.skeleton__element:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 90%;
  background-color: #D9D9D9;
  border-radius: 20px
}

.skeleton__element:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 90%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 100%);
  animation: skeleton_animation 1.5s infinite;
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position: 100% 0;
  border-radius: 10px;
  z-index: 1;
}

.skeleton__tableData--header-titleF {
  display: flex;
  width: 95%;
  height: 30px;
  justify-content: center;
  align-content: center;
  
  &::after {
      background: linear-gradient(90deg, rgba(11, 0, 0, 0) 0%, rgba(83, 79, 79, 0.5) 50%, rgba(30, 1, 1, 0) 100%);
      animation: skeleton_animation 1.5s infinite;
      background-size: 200% 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
  }
}

.skeleton__tableData--header-title {
  display: flex;
  width: 95%;
  height: 30px;
  justify-content: center;
  align-content: center;

  &::after {
      background: linear-gradient(90deg, rgba(11, 0, 0, 0) 0%, rgba(83, 79, 79, 0.5) 50%, rgba(30, 1, 1, 0) 100%);
      animation: skeleton_animation 1.5s infinite;
      background-size: 200% 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
  }
}

.skeleton__tableData--main-result {
  display: flex;
  width: 90%;
  height: 20px;
  justify-content: center;
  align-content: center;
}

.skeleton__right--h6 .skeleton__tableData--header-title {
  width: 50%;
  float: right;
}

.nav-services li a.skeleton__element{
  width: 90px;
  height: 30px;
}



@keyframes skeleton_animation {
  0% {
      background-position: 150% 0
  }

  to {
      background-position: -100% 0
  }
}

[ng-cloak]{ 
  display: none !important; 
}

.sidbar .modalBody > .module:not(#range_desktop) {
  position: relative;
}
.sidbar .modalBody > .module:not(#range_desktop) span.input-group-text{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  right: 32px !important;
  top: 36px !important;
  background-color: #f0eff0 !important;
  padding: 0px !important;
  border-radius: 50%;
}

.sidbar .modalBody > .module:not(#range_desktop) span.input-group-text span{
  font-size: 16px
}



// Mantener abajo Ecribir estilos arriba
/*    --   Partials   --   */

@import "partials/fixedBase";
@import "partials/newCheckout";
@import "partials/newListadoProductos";

@import "partials/checkoutTI";
@import "partials/modal";
@import "partials/skelton";
@import "main/styles";
@import "partials/contactosAgencias";

/*    -- End Partials --   */
